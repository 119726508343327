import create from 'zustand';
import { persist } from 'zustand/middleware';
import { getLangFromString } from '../common/catalogUtils';
import React from 'react';
import { buildAdvQuery } from '../search/NavSearch';

/**
 *
 * Zustand Store that stores the current search information FOR CATALOGS ONLY
 *
 */
export const EXCLUDED_FACETS = ['corresp', 'revision', 'voltoc']; // Type Facets only

export const useCatSearchStore = create(
    persist(
        (set, get) => ({
            debug: false,
            setDebug: (val) => {
                val = !!val;
                set({ debug: !!val });
            },
            active: false, // whether searching is active
            setActive: (val) => {
                val = !!val;
                set({ active: !!val });
            },
            isActive: () => {
                if (!get.isQuery()) {
                    set({ active: false });
                    return false;
                }
                return get().active;
            },
            isNew: false, // whether the search is new and SOLR needs to be queried
            setIsNew: (val) => set({ isNew: !!val }),
            isAdvanced: false, // whether the search is new and SOLR needs to be queried
            setIsAdvanced: (val) => {
                const cleanval = !!val;
                const advd = get().advancedData;
                if (cleanval === false) {
                    advd.rows = [];
                }
                set({
                    isAdvanced: cleanval,
                    advancedData: advd,
                });
            },
            advancedData: {
                docType: 'all',
                docScope: 'all',
                formRows: [],
            },
            setAdvancedData: (dtype = 'all', dscope = 'all', rows) => {
                // get().resetFilters();
                // get().setActive(true);
                get().setIsAdvanced(true);
                const advdata = {
                    docType: dtype,
                    docScope: dscope,
                    formRows: rows,
                };
                // console.log('setting adv data', advdata);
                const newqry = buildAdvQuery(advdata.formRows, advdata.docType);
                set({ advancedData: advdata, query: newqry });
                get().setDisplayQuery();
            },
            convWy: true,
            setConvWy: (val) => set({ convWy: val }),
            showing: false, // whether results are showing
            setShowing: (val) => set({ showing: !!val }),
            query: '*:*', // the query string
            queryLang: 'en',
            isQuery: () => {
                const qry = get().query;
                return qry.length > 0 || qry === '*:*';
            },
            getQuery: () => {
                return get().query;
                /*
                const isAdv = get().isAdvanced;
                const advdata = get().advancedData;
                let qry = get().query;
                // console.log("in get query, is adv", isAdv);
                if (isAdv) {
                    if (advdata?.length > 0) {
                        let aq = buildAdvQuery(advdata.formRows, advdata.docType);
                        set({query: aq});
                        console.log("advanced query", aq);
                        return aq;
                    } else {
                        // console.log("No advanced data");
                    }
                }
                if (!isAdv && qry?.length > 0) {
                    console.log("Not advanced", qry);
                    qry = qry.replace(/"/g, '');
                    return `text:"${qry}"`;
                }
                return '*:*';*/
            },
            setQuery: (qstr) => {
                set({
                    query: qstr,
                    queryLang: getLangFromString(qstr),
                    isActive: true,
                    isAdvanced: false,
                    advancedData: {
                        docType: 'all',
                        docScope: 'all',
                        formRows: [],
                    },
                    isNew: true,
                    facetChange: false,
                });
                get().setDisplayQuery();
            },
            // Returns array of all words or q values from each row of advanced search
            getQueryWords: () => {
                if (get().isAdvanced) {
                    const advdata = get().advancedData;
                    // console.log("advdata", advdata);
                    if (advdata?.formRows?.length > 0) {
                        let qws = advdata?.formRows?.map((row) => {
                            return row.query.replace(/"/g, '');
                        });
                        return qws;
                    }
                    return false;
                } else {
                    const qwrd = get().query;
                    return typeof qwrd === 'string' && qwrd.length > 0
                        ? [qwrd.replace(/"/g, '')]
                        : false;
                }
            },
            solrQuery: {}, // the SOLR options object used to perform the search
            setSolrQuery: (obj) => set({ solrQuery: obj }),
            numFound: 0, // number of search results found
            numBuckets: 0,
            start: 0,
            setStart: (st) => {
                set({ start: st });
            },
            numPerPage: 25,
            setNumPerPage: (pp) => {
                const newTotal = Math.ceil(
                    parseInt(get().buckets?.length) / parseInt(pp)
                );
                set({ numPerPage: pp, totalPages: newTotal });
            },
            hl: false,
            hlflds: '',
            setHighlighting: (hlf) => {
                if (hlf?.length > 0) {
                    set({ hl: true, hlflds: hlf });
                } else {
                    set({ hl: false, hlflds: '' });
                }
            },
            totalPages: 1,
            buckets: [],
            results: null, // the JSON search results converted into a JS Object
            facetTypes: { type: 'Record Type', coll: 'Collection' }, // Dictionary whose keys are solr field names and values are labels to display for facets
            initialFacets: null, // Object that contains the initial facet counts of a search
            filters: { type: ['title', 'textpg'] }, // facet filters
            facetChange: false,
            addFilter: (fq) => {
                // console.log("in add filter: " + fq);
                const flts = get().filters;
                let [nm, value] = fq.split(':');
                if (!nm || !value) {
                    console.log(
                        'not able to parse filter: ' +
                            fq +
                            ' : ' +
                            nm +
                            ' - ' +
                            value
                    );
                    return;
                }
                if (Object.keys(flts).includes(nm)) {
                    if (!flts[nm].includes(value)) {
                        flts[nm].push(value);
                    }
                } else {
                    flts[nm] = [value];
                }
                // console.log('adding filter', flts);
                set({ filters: flts, facetChange: true });
            },
            removeFilter: (fq) => {
                let [nm, value] = fq.split(':');
                const flts = get().filters;
                const vals = flts[nm];
                if (vals?.includes(value)) {
                    vals.splice(vals.indexOf(value), 1);
                    flts[nm] = vals;
                    set({ filters: flts, facetChange: true });
                }
            },
            hasFilter: (fq) => {
                let [nm, value] = fq.split(':');
                const flts = get().filters;
                if (flts && flts[nm]) {
                    return flts[nm].includes(value);
                }
                return false;
            },
            hasFilters: () => {
                const flts = get().filters;
                return flts && Object.keys(flts)?.length > 0;
            },
            resetFilters: () => {
                set({ filters: {} });
            },
            setFilters: (filterobj) => {
                // console.log('in set filters', filterobj);
                set({ filters: filterobj });
            },
            getFacets: () => {
                const resobj = get().results;
                let facets = resobj?.facets;
                //console.log("result factes", facets?.type);
                if (facets?.type) {
                    Object.keys(facets.type).forEach((key) => {
                        if (EXCLUDED_FACETS.includes(key)) {
                            delete facets.type[key];
                        }
                    });
                }
                return [get().facetTypes, facets, get().results?.facetsjson];
            },
            setFacetTypes: (data) => set({ facetTypes: data }),
            setInitialFacets: (facets) => set({ initialFacets: facets }),
            setResults: (res) => {
                // console.log('results in setResults', res);
                const numbuckets = res?.buckets?.length;
                const pp = get().numPerPage;
                const totalP = Math.ceil(numbuckets / pp);
                set({
                    results: res,
                    numFound: res?.numFound,
                    buckets: res?.buckets,
                    numBuckets: numbuckets,
                    totalPages: totalP,
                    facetChange: false,
                });
            },
            reset: () => {
                set({
                    query: '',
                    advancedData: null,
                    isActive: false,
                    isAdvanced: false,
                    isNew: false,
                    facetChange: false,
                    filters: [],
                    solrQuery: {},
                    numFound: 0,
                    results: null,
                    facetTypes: { type: 'Record Type', coll: 'Collection' },
                    initialFacets: null,
                });
                get().setDisplayQuery();
            },
            displayQuery: ' Everything',
            setDisplayQuery: () => {
                let dq = ' Everything';
                let qstr = get().query?.replace(/\"/g, '');
                if (get().isAdvanced) {
                    dq = get().getQueryWords();
                } else if (qstr && qstr.length > 0) {
                    dq = qstr;
                }
                set({ displayQuery: dq });
            },
        }),
        {
            name: 'cat-search-store', // Unique name for localStorage
        }
    )
);
