import create from 'zustand';
import { unset } from 'lodash';

/**
 * To Store Catalog settings
 * @type {UseStore<{getTargetString: function(): string, setTarget: function(*, string=, string=): void, targets: {ngb: {pt: string}, lccw: {main: string}}}>}
 */
export const useCatSettings = create((set, get) => ({
    targets: {
        kt: {
            d: ['all'],
        },
        lccw: {
            main: ['all'],
        },
        ngb: {
            pt: ['all'],
        },
    },
    targString: 'kt-d.all,lccw.all,ngb-pt.all',
    setTarget: (coll, ed = 'main', targscope = 'all') => {
        const targs = get().targets;
        const setTargetString = get().setTargetString;
        // Do targets already include collection?
        if (Object.keys(targs).includes(coll)) {
            // Does targes[collection] already include edition?
            if (Object.keys(targs[coll]).includes(ed)) {
                // Make sure targscope is not already there before adding it
                if (!targs[coll][ed].includes(targscope)) {
                    if (targscope === 'all') {
                        // if targscope is all, just replace everything else
                        targs[coll][ed] = [targscope];
                    } else {
                        if (targs[coll][ed][0] === 'all') {
                            targs[coll][ed][0] = targscope;
                        } else {
                            targs[coll][ed].push(targscope);
                        }
                    }
                } // If targscope already in ed, then do not add. So no else here
            } else {
                // No ed in coll: just add ed as array with targscope
                targs[coll][ed] = [targscope];
            }
        } else {
            // No coll, then add ed and coll
            targs[coll] = {};
            targs[coll][ed] = [targscope];
        }
        set((state) => ({ targets: targs }));
        setTargetString();
    },
    removeTarget: (coll, ed) => {
        const targs = get().targets;
        const setTargetString = get().setTargetString;
        if (
            Object.keys(targs).includes(coll) &&
            Object.keys(targs[coll]).includes(ed)
        ) {
            delete targs[coll][ed];
            set((state) => ({ targets: targs }));
            setTargetString();
        }
    },
    setTargetString: () => {
        const targs = get().targets;
        let targparts = [];
        for (let coll in targs) {
            for (let ed in targs[coll]) {
                let tstr = coll;
                // If ed is "main" there is only one edition in collection, so no sigla used.
                if (ed !== 'main') {
                    tstr += '-' + ed;
                }
                // TODO: expand to account for multiple targets in ed. Talk to Tennom about syntax
                tstr += '.' + targs[coll][ed][0]; // Only uses first target in ed
                targparts.push(tstr);
            }
        }
        set((state) => ({ targString: targparts.join(',') })); // e.g. lccw.all,ngb-pt.all
    },
}));
