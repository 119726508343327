import React, { useEffect, useState } from 'react';
import { FeatureCollection } from '../common/FeatureCollection';
import { useParams } from 'react-router-dom';
import MandalaSkeleton from '../common/MandalaSkeleton';
import { useSolr } from '../../hooks/useSolr';

export function RelatedTerms({ subjectId }) {
    const { viewMode } = useParams();

    const [perPage, setPerPage] = useState(50); // These are the rows returned
    const [page, setPage] = useState(0); // Start will always be page * perPage
    const start_row = page * perPage;

    const terms_query = {
        index: 'terms',
        params: {
            q: `associated_subject_ids:${subjectId}`,
            fq: 'tree:terms',
            fl: '*',
            start: start_row,
            rows: perPage,
            sort: 'cascading_position_i ASC',
        },
    };

    // console.log("Terms query", terms_query);
    const {
        isLoading: isRelTermLoading,
        data: relTerms,
        isError: isRelTermError,
        error: relTermError,
    } = useSolr(
        ['subjects', subjectId, 'relterms', start_row, perPage],
        terms_query,
        false,
        false
    );

    if (isRelTermLoading) {
        return (
            <div>
                <MandalaSkeleton />
            </div>
        );
    }

    if (isRelTermError) {
        return (
            <div>
                <span>Error: {relTermError.message}</span>
            </div>
        );
    }

    const docs = relTerms?.docs ?? [];
    const numFound = relTerms?.numFound ?? 0;
    let output = (
        <div className="related-terms-gallery">
            <FeatureCollection
                docs={docs}
                viewMode={viewMode}
                inline={false}
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                isPreviousData={false}
                assetCount={numFound}
                showSearchFilters={true}
                hasMore={numFound <= page * perPage + perPage ? false : true}
            />
        </div>
    );
    return output;
}
