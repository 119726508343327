import { useQuery } from 'react-query';
import axios from 'axios';
import jsonpAdapter from '../logic/axios-jsonp';
import { getSolrUrls } from './utils';
import { GetSessionID } from '../main/MandalaSession';
import { getProject } from '../views/common/utils';

// Declare all needed constants.
const solr_urls = getSolrUrls();
const ALL = [
    'audio-video',
    'images',
    'texts',
    'visuals',
    'sources',
    'subjects',
    'places',
    'terms',
];
const facetJson = JSON.stringify({
    asset_counts: {
        limit: 20,
        type: 'terms',
        field: 'asset_type',
        domain: { excludeTags: 'ast' },
    },
});

/**
 * useKmapRelated is a custom React Hook implementing useQuery from react-query to query
 * SOLR indices for information concerning kmaps related assets and their counts.
 *
 * @param kmapid
 * @param type
 * @param start
 * @param rows
 * @param definitionID
 * @param filtered
 * @param enabled
 * @param relsubjects
 * @returns {any}
 */
export function useKmapRelated(
    kmapid,
    type = 'all',
    start = 0,
    rows = 100,
    definitionID = 'noDefID',
    filtered = true,
    enabled = true,
    relsubjects = []
) {
    const asset_types = type === 'all' ? ALL : [type];

    return useQuery(
        [
            'kmapRelated',
            kmapid,
            asset_types.join('-'),
            start,
            rows,
            definitionID,
            filtered,
            relsubjects,
        ],
        () =>
            getKmapRelatedData(
                kmapid,
                asset_types,
                start,
                rows,
                definitionID,
                filtered,
                relsubjects
            ),
        { keepPreviousData: true, enabled: enabled }
    );
}

const getKmapRelatedData = async (
    kmapid,
    asset_types,
    start,
    rows,
    definitionID,
    filtered,
    relsubjects
) => {
    const defID =
        definitionID === 'noDefID' || String(definitionID) === 'any'
            ? kmapid
            : definitionID;
    const relsubjqry =
        relsubjects?.length > 0 ? ` OR uid:(${relsubjects.join(' ')})` : '';
    const kid = kmapid.includes('-') ? kmapid.split('-')[1] : kmapid;
    let params = {
        fl: '*',
        wt: 'json',
        start: start,
        rows: rows,
        'json.facet': facetJson,
        // eslint-disable-next-line
        //q: `(uid:${kmapid}^100+kmapid:${defID})`,
        q: `kmapid:${defID}${relsubjqry}`,
        kmapid: kmapid,
        fq: [
            '{!tag=ast}asset_type:(' + asset_types.join(' ') + ')',
            `-uid:${kmapid}`,
        ],
        // test:'tryme'
    };
    const sid = GetSessionID();
    if (sid) {
        params.sid = sid;
    }

    // Filter by project?
    const project = getProject();
    if (project && filtered) {
        let q = params.q;
        if (!q.includes('projects_ss:')) {
            q += ` AND projects_ss:${project}`;
        }
        params.q = q;
    }

    const request = {
        adapter: jsonpAdapter,
        callbackParamName: 'json.wrf',
        url: solr_urls.assets,
        params: params,
    };

    const { data } = await axios.request(request);
    return data;
};
