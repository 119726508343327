import React, { useEffect, useState } from 'react';
import { useCatSearchStore } from '../hooks/useCatSearchStore';
import { useCatSolr } from '../hooks/useCatSolr';
import THLSkeleton from '../common/THLSkeleton';
import Form from 'react-bootstrap/Form';
import { BsSliders } from 'react-icons/bs';
import { useCookies } from 'react-cookie';
import MandalaSkeleton from '../../views/common/MandalaSkeleton';
import Collapse from 'react-bootstrap/Collapse';
import {
    IoMdArrowDropright,
    IoMdArrowDropdown,
    IoMdCheckmark,
} from 'react-icons/all';
import { NavSearch } from './NavSearch';
import { useBiblStore } from '../hooks/useBiblStore';
import { getCollTitle, getMainTitle } from '../common/catalogUtils';
import { NavBasicSearch } from './NavBasicSearch';

// Facet labels are a dictionary that connects a facet machine name with its display label.
// If not found as a key in this dictionary, the machine name is used as the default label.
const facetlabels = {
    agent: 'agents',
    chapter: 'chapter title',
    chapgroup: 'chapter group',
    doxcat: 'doxographic genre',
    physfacet: 'physical attribute',
    section: 'section title',
    textpg: 'text page',
    textsection: 'text section',
    tibbibl: 'text records',
    publication_stmt: 'publication info',
};

const openIcon = <IoMdArrowDropdown />;
const closedIcon = <IoMdArrowDropright />;
/**
 * The overall search facet div placed in a sidebar
 *
 * @param props
 * @returns {JSX.Element|null}
 */
export default function SearchFacets(props) {
    const search = useCatSearchStore();
    // console.log(search);
    const [ftypes, facets, jfacets] = search?.getFacets();
    // console.log("ftypes", ftypes);
    // console.log("facet query", search?.query);
    let qstr = '*:*';
    if (search?.query) {
        let sqs = search.query.trim();
        sqs =
            sqs.includes('*') || sqs.includes(':') ? search.query : `"${sqs}"`;
        qstr = sqs.includes(':') ? sqs : `text:${sqs}`;
    }
    // Get Totals for all facets
    const qobj = {
        q: qstr,
        fl: `*,[child limit=5000]`,
        facet: true,
        'facet.field': Object.keys(ftypes),
        'facet.mincount': 0,
        'json.facet': [
            '{"coll": {"type":"terms", "field":"coll","limit":-1,"mincount":0, "facet":{"eds":{"type":"terms","field":"edsig","limit":-1}}}}',
        ],
    };

    const {
        isLoading: isSearchLoading,
        data: searchres,
        isError: isSearchError,
        error: searchError,
    } = useCatSolr(qobj);

    if (isSearchLoading) {
        return <MandalaSkeleton />;
    }
    if (isSearchError) {
        console.log('search facets error', searchError);
        return <p>Error in facet calculation</p>;
    }

    if (!facets || !ftypes) {
        // console.log('no facets or types', facets, ftypes);
        return null;
    }

    // console.log('catalog search filters', qobj, searchres);

    return (
        <div className="c-search-facets">
            <h1>
                <span className="icon">
                    <BsSliders />
                </span>{' '}
                Filters
            </h1>
            {Object.keys(ftypes)
                .sort()
                .map((k, fti) => {
                    const fname = ftypes[k];
                    const totals =
                        k === 'coll'
                            ? searchres.facetsjson.coll
                            : searchres.facets[k];
                    return (
                        <FacetBox
                            key={[k, fti]}
                            name={k}
                            label={fname}
                            data={facets[k]}
                            collfacets={jfacets?.coll}
                            totals={totals}
                        />
                    );
                })}
        </div>
    );
}

/**
 * A facet box displays the result for a single facet, such as types. It shows all the values for the facet as choices
 *
 * @param name
 * @param label
 * @param data
 * @returns {JSX.Element|null}
 * @constructor
 */
function FacetBox({ name, label, data, collfacets, totals }) {
    const [open, setOpen] = useState(true);
    if (!data || !Object.keys(data)) {
        return null;
    }
    const toggleOpen = (e) => {
        e.preventDefault();
        setOpen(!open);
    };
    // Filter out blank keys and sort
    const fkeys = Object.keys(data)
        .filter((fk) => fk)
        .sort();

    let facets = null;
    if (name === 'coll') {
        facets = (
            <FacetColls
                facetnm={name}
                label={label}
                count={data}
                collcounts={collfacets}
                totals={totals}
            />
        );
    } else {
        facets = fkeys.map((fk, fki) => {
            // Get alternate labels if different from key. See constant above.
            const flab = Object.keys(facetlabels).includes(fk)
                ? facetlabels[fk]
                : fk;
            const totalct =
                totals && Object.keys(totals).includes(fk) ? totals[fk] : null;
            return (
                <li key={['facets', name, fki]} className="list-unstyled">
                    <FacetItem
                        facetnm={name}
                        facetval={fk}
                        label={flab}
                        count={data[fk]}
                        total={totalct}
                    />
                </li>
            );
        });
    }
    const toggleclass = open ? 'facetopen' : 'facetclosed';
    const icon = open ? openIcon : closedIcon;
    return (
        <div className="c-facet-box">
            <h2>
                <a
                    className={`facet-toggle ${toggleclass}`}
                    onClick={toggleOpen}
                >
                    {icon}
                </a>{' '}
                {label}
            </h2>
            <Collapse in={open}>
                <div>{facets}</div>
            </Collapse>
        </div>
    );
}

function FacetColls(props) {
    // console.log('facet colls props', props);
    const totals = props?.totals;
    if (!totals) {
        return null;
    }
    const lis = Object.keys(totals).map((k, ki) => {
        return <CollFacet key={`facet-coll-${k}-${ki}`} coll={k} {...props} />;
    });
    return <>{lis}</>;
}

function CollFacet(props) {
    const { collinfo } = useBiblStore();
    const { hasFilter } = useCatSearchStore();
    const { coll, counts, collcounts, totals, facetnm, facetval } = props;
    const [open, setOpen] = useState(false);

    const collbibl = collinfo?.colls[coll]?.bibl;
    const colleds = collinfo?.colls[coll]?.eds;
    let collTitle = '';
    if (collbibl) {
        collTitle = getCollTitle(collbibl);
    }
    // console.log("Coll facet", coll, collinfo, collTitle, collbibl);

    useEffect(() => {
        if (hasFilter(`coll:${coll}`)) {
            setOpen(true);
        }
    }, []);

    const toggleOpen = (e) => {
        e.preventDefault();
        setOpen(!open);
    };
    if (!collcounts || !totals) {
        // console.log('no counts or totals in CollFacet', props);
        return null;
    }
    const data = Object.keys(collcounts).includes(coll)
        ? collcounts[coll]
        : false;
    const colltotals = Object.keys(totals).includes(coll)
        ? totals[coll]
        : false;
    if (colltotals === 0) {
        return null;
    }
    let eds = Object.keys(colltotals).filter(
        (k, ki) => !['total', 'main'].includes(k)
    );
    /** To remove capitalized versions of edsigs can do this below, but we need to normalize when indexing so that
     * the counts are accurate:
        .map((edkey) => ( edkey?.toLowerCase() ));
    eds = Array.from(new Set(eds)); // get unique values since in NGB some ed sigs are capitalized
        It appears that it was the corresp records that had the capital ed siglas. Fixing in XSL. Need to check for others.
     */
    // console.log("eds", eds);
    const lis =
        eds?.length === 0 ? null : (
            <ul>
                {eds.map((edsig, edi) => {
                    const eddata = Object.keys(data).includes(edsig)
                        ? data[edsig]
                        : 0;
                    const edtotal = Object.keys(colltotals).includes(edsig)
                        ? colltotals[edsig]
                        : 0;
                    let edtitle = '';
                    if (colleds && Object.keys(colleds)?.includes(edsig)) {
                        edtitle = colleds[edsig]?.bibl?.ed;
                    }
                    return (
                        <li
                            key={`facet-coll-ed-${edsig}-${edi}`}
                            className="list-unstyled"
                        >
                            <FacetItem
                                facetnm="edsig"
                                coll={coll}
                                facetval={edsig}
                                label={edsig}
                                title={edtitle}
                                count={eddata}
                                total={edtotal}
                            />
                        </li>
                    );
                })}
            </ul>
        );

    let toggleclass = open ? 'facetopen' : 'facetclosed';
    if (!eds || eds?.length === 0) {
        toggleclass = 'nofacets';
    }
    const icon = open ? openIcon : closedIcon;
    return (
        <div className={`c-collfacet ${coll}`}>
            <a className={`facet-toggle ${toggleclass}`} onClick={toggleOpen}>
                {icon}
            </a>{' '}
            <FacetItem
                facetnm={facetnm}
                facetval={coll}
                label={coll}
                eds={eds}
                title={collTitle}
                count={data?.total || 0}
                total={colltotals?.total || 0}
            />
            <Collapse in={open}>
                <div>{lis}</div>
            </Collapse>
        </div>
    );
}

/**
 * Displays a facet item in the list of search facets with a checkbox, label, and hitcount
 *
 * @param facet
 * @param facetitem
 * @param label
 * @param count
 * @returns {JSX.Element}
 * @constructor
 */
function FacetItem(props) {
    const { facetnm, facetval, label, title, count, total } = props;
    const fq = `${facetnm}:${facetval}`;
    // console.log("facet item", fq);
    const searchStore = useCatSearchStore();
    const { filters, hasFilter, addFilter, removeFilter } = searchStore;
    const [cookies, setCookie] = useCookies(['thlcat_searchfilters']);
    const [checked, setChecked] = useState(hasFilter(fq));

    useEffect(() => {
        if (hasFilter(fq)) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [searchStore]);

    useEffect(() => {
        if (!checked && facetnm === 'coll' && props?.eds?.length > 0) {
            props.eds.forEach((edsig) => {
                removeFilter(`edsig:${edsig}`);
            });
        }
    }, [checked]);

    const ctval = count * 1;
    if (total) {
        const totval = total * 1;
        //console.log(facetval, ctval, totval);
        if (totval === 0 && ctval === 0) {
            return null; // Don't display facets with no total hits
        }
    } else {
        return null; // Don't display facets with no total hits
    }
    const toggleFilter = (e) => {
        const facet = e.target.value;
        if (!checked) {
            if (facetnm === 'edsig' && props?.coll) {
                addFilter(`coll:${props.coll}`);
            }
            addFilter(facet);
            setChecked(true);
        } else {
            if (facetnm === 'edsig' && props?.coll) {
                removeFilter(`coll:${props.coll}`);
            }
            removeFilter(facet);
            setChecked(false);
        }
        setCookie('thlcat_searchfilters', JSON.stringify(filters));
    };

    // if (count === 0) { return null; }

    if (facetval === 'voltoc') {
        return null;
    } // Volume tocs are just lists of text titles so hit here are redundant.
    return (
        <div className="facet-filter">
            <Form.Check
                type="checkbox"
                id={`filter-${facetval}`}
                label={label}
                value={fq}
                inline
                checked={checked ? 'checked' : false}
                onChange={toggleFilter}
                title={title}
            />
            <span className="facet-count">{total}</span>
        </div>
    );
}
