import React from 'react';
import { useCatSearchStore } from '../hooks/useCatSearchStore';
import { useCatSolr } from '../hooks/useCatSolr';

/**
 * Shows the parenthetical hit types after the item label in the search results list
 * Commented out code was attempt to classify hit types that wasn't working
 *
 * @param bibid
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function HitsSummary({ bibid, count }) {
    // console.log(bibid, count);
    return <>({count} hits)</>;
    /*const search = useCatSearchStore();
    let qobj = search.solrQuery;
    const dbugid = 'dkcw-0014';
    if (bibid.includes(dbugid)) {
        console.log('Debug id: ' + dbugid);
        console.log('Retrieved solr query objecxt for dbid', qobj);
    }
    let fq = Array.isArray(qobj.fq)
        ? qobj.fq.filter((it) => !it.includes('bibid_s:'))
        : typeof qobj.fq === 'string'
        ? [qobj.fq]
        : [];
    fq.push(`bibid_s:${bibid}`);
    qobj.fq = fq;
    if (bibid.includes(dbugid)) {
        console.log('new qobj', qobj);
    }
    const {
        isLoading: isHitsLoading,
        data: hitsearch,
        isError: isHitsError,
        error: hitsError,
    } = useCatSolr(qobj);

    //console.log(bibid, search)
    if (isHitsError || isHitsLoading || !hitsearch?.facets?.type) {
        return null;
    }*/

    // console.log(hitsearch);
    /*
    const hittypes = Object.keys(hitsearch?.facets?.type).map((ky, kyi) => {
        switch (ky) {
            case 'textpg':
            case 'textsection':
                return 'text';
            case 'voltoc':
                return 'table of contents';
            default:
                return ky;
        }
    });
    const hitsum = hittypes.join(', ');*/
}
