import React, { useEffect, useMemo, useState } from 'react';
import { useCatSearchStore } from '../hooks/useCatSearchStore';
import { useCatSolr } from '../hooks/useCatSolr';
import THLSkeleton from '../common/THLSkeleton';
import { ResultItem } from './ResultItem';
import { FeaturePager } from '../../views/common/FeaturePager/FeaturePager';
import { useLocation, useHistory } from 'react-router-dom';
import { enterForClick } from '../../views/common/utils';
import { highlightSearch } from '../common/catalogUtils';

/**
 * Performs the search based on the query and other parameters in the SearchStore and returns the results
 * which is a list of records that have Bibl IDs.
 * These are generally texts, but could be volumes, doxcats, etc.
 * The hits within a particular bibid item are combined into a single hitlist using json.facets
 * combining results into unlimited buckets based on bibid_s field.
 * The hitlist is displayed as a toggle div below the bibid records title.
 *
 * Other components used to create the results page are below:
 *      ResultItem
 *      HitSummary
 *      HitList
 *
 * As well as the function getHit(hit, page, qry)
 *
 * @param facets
 * @returns {JSX.Element}
 * @constructor
 */
export default function SearchResults() {
    // facets = { type: 'Record Type', coll: 'Collection', edsig: 'Edition' },
    const search = useCatSearchStore();
    const loc = useLocation();
    const history = useHistory();
    let [qstr, setQstr] = useState('*:*');
    /*
    let paramQuery =
        loc?.search?.length > 0
            ? decodeURIComponent(loc.search.replace('?q=', ''))
            : false;
    if (typeof paramQuery === 'string' && paramQuery.includes('&coll=')) {
        [paramQuery, coll] = paramQuery.split('&coll=');
    }

     */
    let { start, buckets, numPerPage } = search;
    // let query = search.getQuery();

    //const [srchquery, setSrchQuery] = useState(query);
    const [showBuckets, setShowBuckets] = useState([]);
    const [spgnum, setSearchPageNum] = useState(0);
    const [openAll, setOpenAll] = useState(false);

    // console.log('Cat Query string', qstr);
    const qobj = {
        q: qstr,
        fl: `*,[child limit=5000]`,
        facet: true,
        'facet.field': Object.keys(search.facetTypes),
        'json.facet': [
            '{"texthits": {"type": "terms", "field": "bibid_s", "limit":-1 }}',
            '{"type": {"type":"terms", "field":"type","limit":-1,"mincount":0}}',
            '{"coll": {"type":"terms", "field":"coll","limit":-1,"mincount":0,"facet":{"eds":{"type":"terms","field":"edsig","limit":-1}}}}',
        ],
        rows: numPerPage,
    };

    // console.log('qobj', qobj);
    // Add User Chosen Facets to Query
    if (search?.hasFilters()) {
        const newfq = [];
        for (const [key, value] of Object.entries(search.filters)) {
            if (value.length > 0) {
                newfq.push(key + ':(' + value.join(' OR ') + ')');
            }
        }
        qobj.fq = newfq;
    }

    if (search?.hl) {
        qobj.hl = true;
        qobj['hl.fl'] = search.hlflds;
    }
    // console.log('final qobj for querying SOLR', qobj);

    const {
        isLoading: isSearchLoading,
        data: searchres,
        isError: isSearchError,
        error: searchError,
    } = useCatSolr(qobj);

    // If Search string in path .../kw/{search string}, set query to invoke search
    useEffect(() => {
        // console.log('path', loc.pathname);
        if (loc.pathname.includes('/kw/')) {
            const ppts = loc.pathname.split('/kw/');
            const kw = ppts.pop();
            search.setQuery(`text:${kw}`);
            history.push('/catalog/search');
        }
        let paramQuery =
            loc?.search?.length > 0
                ? decodeURIComponent(loc.search.replace('?q=', ''))
                : false;
        if (paramQuery && typeof paramQuery === 'string') {
            let coll = false;
            [paramQuery, coll] = paramQuery.split('&coll=');
            if (coll && coll.length > 0) {
                search.resetFilters();
                search.addFilter(`coll:${coll}`);
            }
            // console.log(paramQuery);
            search.setQuery(paramQuery);
            history.push('/catalog/search');
        }
    }, []);

    useEffect(() => {
        let newqstr = search.query;
        if (!newqstr) {
            return;
        }
        if (!search.isAdvanced) {
            if (!newqstr.includes(':')) {
                if (!newqstr.includes('"')) {
                    newqstr = `"${newqstr}"`;
                }
                newqstr = `text:${newqstr}`;
            }
        }
        // console.log(search.isAdvanced);
        // console.log("new qstr: ", newqstr);
        if (newqstr !== qstr) {
            setQstr(newqstr);
        }
    }, [search?.query]);

    useEffect(() => {
        // console.log("search res from solr", searchres);
        if (!isSearchLoading && !isSearchError) {
            search.setSolrQuery(qobj);
            // search.setFacetTypes(facets);
            search.setResults(searchres);
            if (!search?.facetChange) {
                search.setInitialFacets(searchres?.facets);
            }
        }
    }, [searchres]);

    useEffect(() => {
        const numpgs = buckets?.length / numPerPage;
        if (spgnum > -1 && spgnum <= numpgs) {
            const newStart = numPerPage * spgnum;
            search.setStart(newStart);
        }
    }, [spgnum]);

    useEffect(() => {
        if (Array.isArray(buckets)) {
            let endNum = start + numPerPage;
            if (search.isActive) {
                endNum += 1;
            }
            const newbuckets = buckets.slice(start, endNum);
            setShowBuckets(newbuckets);
        }
    }, [start, buckets, numPerPage]);

    if (isSearchLoading) {
        return <THLSkeleton />;
    }

    if (isSearchError) {
        console.log('Search error', searchError);
    }
    // console.log('search results', searchres);

    const setSearchPerPage = (ppn) => {
        if (search?.setNumPerPage) {
            search.setNumPerPage(ppn);
        }
    };

    /*
    const setSearchPage = (pg) => {
        console.log("in set page", typeof(pg), `${pg()}`);
        const newpg = parseInt(pg);
        const numpgs = buckets?.length / numPerPage;
        if (newpg > -1 && newpg <= numpgs) {
            const newStart = numPerPage * newpg;
            setSearchPageNum(newpg);
            search.setStart(newStart);
        }
    };

     */

    const toggleOpen = (e) => {
        e.preventDefault();
        setOpenAll(true);
    };
    const toggleClosed = (e) => {
        e.preventDefault();
        setOpenAll(false);
    };

    let querylabel = search.getQueryWords();
    if (Array.isArray(querylabel)) {
        if (querylabel.length === 1) {
            querylabel = querylabel[0];
            if (querylabel === '*:*') {
                querylabel = 'Everything';
            }
        } else {
            const last = querylabel.pop();
            querylabel = querylabel.join(', ') + ` and ${last}`;
        }
    }

    // console.log("results", searchres);
    // console.log("buckets", showBuckets);

    return (
        <div id="srch-results">
            <div className="l-search_head">
                <h1>
                    Search for {querylabel}{' '}
                    {/*<Link
                        to={'/catalog'}
                        onClick={search?.reset}
                        className="backlink"
                    >
                        New Search
                    </Link>{' '} */}
                </h1>
                {search?.isActive && (
                    <div className="toggles">
                        <a onClick={toggleOpen} onKeyUp={enterForClick}>
                            Expand All
                        </a>{' '}
                        | {` `}
                        <a onClick={toggleClosed} onKeyUp={enterForClick}>
                            Collapse All
                        </a>
                    </div>
                )}
            </div>
            {/* When there are results found */}
            {search?.numFound > 0 && (
                <>
                    <FeaturePager
                        viewMode="list"
                        assetCount={search.numFound}
                        itemCount={buckets?.length}
                        perPage={numPerPage}
                        totalPages={search?.totalPages}
                        page={spgnum}
                        setPerPage={setSearchPerPage}
                        setPage={setSearchPageNum}
                    />
                    {/* <ol className="results" start={start + 1}> */}
                    <ul className="results">
                        {' '}
                        {/* list-unstyled */}
                        {showBuckets?.map((doc, di) => {
                            return (
                                <ResultItem
                                    key={['result', di]}
                                    bibid={doc?.val}
                                    count={doc?.count}
                                    open={openAll}
                                />
                            );
                        })}
                    </ul>
                </>
            )}

            {/* When there are no results */}
            {search?.numFound === 0 && <NoResults />}
        </div>
    );
}

function NoResults() {
    const query = useCatSearchStore((state) => state?.query);
    return (
        <div className="no-results">
            <p>No results were found for your query: {query}</p>
        </div>
    );
}
