import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import KmapTree from '../../KmapTree/KmapTree';
import HistoryViewer from '../../History/HistoryViewer';
import { useKmapRelated } from '../../../hooks/useKmapRelated';
import { getSolrUrls, useUnPackedMemoized } from '../../../hooks/utils';
import { getProject, queryID } from '../../../views/common/utils';
import './RelatedsViewer.scss';
import { useHistory } from '../../../hooks/useHistory';
import MandalaSkeleton from '../../common/MandalaSkeleton';
import { useKmapRelatedTerms } from '../../../hooks/useKmapRelatedTerms';
import { useKmap } from '../../../hooks/useKmap';
import { useSolr } from '../../../hooks/useSolr';

const solrurls = getSolrUrls();

export function RelatedsViewer() {
    let statePages = useHistory((state) => state.pages);
    /*
    // Remove current page from list so that it doesn't show
    statePages = Array.from(statePages);
    statePages = statePages.filter((sp) => {
        return !sp.includes('::' + window.location.pathname);
    });
    */
    const match = useRouteMatch([
        '/:baseType/:id/related-:type/:definitionID/view/:relID',
        '/:baseType/:id/related-:type/:definitionID/:viewMode',
        '/:baseType/:id/related-:type',
        '/:baseType/:id',
    ]);

    const loc = match?.params.type || 'home';
    let locMatch = {};
    locMatch[loc] = 'active';

    // const [relCount, setRelCount] = useState(0);

    let baseArgs = {
        baseType: match?.params.baseType,
        id: match?.params.id,
    };
    const kmapid = queryID(baseArgs.baseType, baseArgs.id);

    const {
        isLoading: isRelatedSubjLoading,
        data: relatedSubjData,
        isError: isRelatedSubjError,
        error: relatedSubjError,
    } = useKmapRelatedTerms(kmapid, 'all', 0, 100, 'noDefID', false);

    const relSubIds =
        !isRelatedSubjLoading && !relatedSubjError
            ? relatedSubjData.map((rs) => rs?.related_subjects_id_s)
            : [];

    const {
        isLoading: isRelatedLoading,
        data: relatedData,
        isError: isRelatedError,
        error: relatedError,
    } = useKmapRelated(
        kmapid,
        'all',
        0,
        100,
        'noDefID',
        false,
        !isRelatedSubjLoading,
        relSubIds
    );

    //Unpack related data using memoized function
    let kmapsRelated = useUnPackedMemoized(
        relatedData,
        baseArgs.id,
        'all',
        0,
        100
    );

    const {
        isLoading: isKmapLoading,
        data: kmapData,
        isError: isKmapError,
        error: kmapError,
    } = useKmap(kmapid, 'info', false, false);

    // Added query to find terms related to subjects.
    const terms_query = {
        index: 'terms',
        params: {
            q: `associated_subject_ids:${baseArgs.id}`,
            fq: 'tree:terms',
            fl: '*',
            start: 0,
        },
    };
    if (baseArgs.baseType === 'terms') {
        terms_query.params.q = `uid:terms-${baseArgs.id}`;
        terms_query.params.fl = 'associated_subject_ids';
    }

    // console.log("Terms query", terms_query);
    const {
        isLoading: isRelTermLoading,
        data: relTerms,
        isError: isRelTermError,
        error: relTermError,
    } = useSolr([kmapid, 'relterms'], terms_query, false, false);

    /*
    useEffect(() => {
        //Set related child subject count from second query
        const relchildct = relatedSubjData?.response?.numFound * 1;
        if(baseArgs?.relateds?.assets?.subjects?.count) {
            console.log("Subject count", baseArgs.relateds.assets.subjects.count);
        }
        if (!isNaN(relchildct)) {
            setRelCount(relchildct)
        }

    }, [relatedSubjData, baseArgs]);

     */

    if (!baseArgs.id || !baseArgs.baseType) {
        return null;
    }

    if (
        isRelatedLoading ||
        isKmapLoading ||
        isRelatedSubjLoading ||
        isRelTermLoading
    ) {
        return (
            <aside className="l-column__related">
                <div className="l-column__related__wrap">
                    <section className="l-related__list__wrap">
                        <MandalaSkeleton
                            count={10}
                            height={25}
                            width={'100%'}
                            marginTop={12}
                        />
                    </section>
                </div>
            </aside>
        );
    }

    if (isRelatedError) {
        return (
            <aside className="l-column__related">
                <div className="l-column__related__wrap">
                    <section className="l-related__list__wrap">
                        <span>Error: {relatedError.message}</span>
                    </section>
                </div>
            </aside>
        );
    }
    if (isKmapError || isRelTermError) {
        console.error(
            'KMap loading error in related viewer',
            kmapError,
            relTermError
        );
    }

    baseArgs.relateds = kmapsRelated;
    const totalRelateds = kmapsRelated?.assets?.all.count || 0;
    const noneClass = totalRelateds === 0 ? ' none' : '';
    // To Increase counts by another search other than the standard relateds (e.g. terms query for related child subjects)
    // These values are added to the baseArgs.relateds.{asset type}.count value when displayed. Must be keyed on asset type
    baseArgs.augments = {
        subjects: relatedSubjData?.response?.numFound * 1, // Now that we've switch order of query and add subject uids directly to the asset query not sure we need this...
    };
    // Terms related subjects are not childDocuments so are not counted. Adding that special case here.
    // See https://uvaissues.atlassian.net/browse/MANU-7948
    if (
        baseArgs.baseType === 'terms' &&
        kmapData?.associated_subjects?.length > 0
    ) {
        const assocSubjs = kmapData.associated_subjects
            .filter((subj, si) => kmapData.associated_subject_ids[si] !== 9315) // 9315 is expression, used to identify all terms
            .map((subj, si) => {
                return `${subj}|${kmapData.associated_subject_ids[si]}`;
            });
        kmapsRelated.assets.subjects = {
            count: assocSubjs.length,
            docs: assocSubjs,
        };
        // Only add extra subjects if 0 because other wise it adds with each react refresh, possibly because memoized?
        if (kmapsRelated.assets.all.count === 0) {
            kmapsRelated.assets.all.count = kmapData.associated_subjects.filter(
                (subj, si) => kmapData.associated_subject_ids[si] !== 9315
            ).length;
        }
    }
    // Second kludge to show related terms in sidebar

    if (!kmapsRelated?.assets) {
        kmapsRelated = {
            assets: {
                all: {
                    count: 0,
                    docs: [],
                },
                terms: {
                    count: 0,
                    docs: [],
                },
            },
        };
    }
    if (baseArgs.baseType === 'subjects' && relTerms?.numFound > 0) {
        kmapsRelated.assets.terms = {
            count: relTerms.numFound,
            docs: relTerms.docs,
        };
    }
    if (baseArgs.baseType === 'terms' && kmapData?._childDocuments_) {
        let rtrms = kmapData._childDocuments_.filter(
            (cd, cdi) =>
                cd.block_child_type === 'related_terms' &&
                cd.related_terms_relation_code_s !== 'heads'
        );
        // Remove duplicate children
        rtrms = Object.values(
            rtrms.reduce((acc, obj) => {
                acc[obj.id] = obj;
                return acc;
            }, {})
        );
        kmapsRelated.assets.terms = {
            count: rtrms.length,
            docs: rtrms,
        };
    }
    let allct = 0;
    for (const [key, asset] of Object.entries(kmapsRelated.assets)) {
        allct += asset.count * 1;
    }
    kmapsRelated.assets.all.count = allct;

    let allaugct = 0;
    for (const [key, val] of Object.entries(baseArgs.augments)) {
        allaugct += val * 1;
    }
    baseArgs.augments.all = allaugct;

    // console.log("kdata in relateds viewer", kmapData);
    // console.log("KMaps related", kmapsRelated);
    // console.log("Related viewere base args", baseArgs);

    // Link class was: c-related__link--home c-related__item nav-item nav-link ${locMatch['home']}
    //  console.log("total relateds", totalRelateds, baseArgs);

    return (
        <aside className={'l-column__related'}>
            <div className="l-column__related__wrap">
                <section className="l-related__list__wrap">
                    {totalRelateds === 0 &&
                        baseArgs.relateds.assets.all.count === 0 && (
                            <>
                                <div className="u-related__list__header">
                                    Related Resources
                                </div>
                                <ul>
                                    <li>
                                        <span>None</span>
                                    </li>
                                </ul>
                            </>
                        )}
                    {totalRelateds > 0 && (
                        <>
                            <div className="u-related__list__header">
                                <Link
                                    id="sui-rl-Home"
                                    to={
                                        '/' +
                                        baseArgs.baseType +
                                        '/' +
                                        baseArgs.id
                                    }
                                    className={``}
                                >
                                    Overview
                                </Link>
                            </div>
                            <hr />
                            <div className="u-related__list__header">
                                Related Resources
                            </div>
                            <nav
                                className={`c-relatedViewer nav nav-tabs flex-column${noneClass}`}
                            >
                                {/*
                                    Terms for now only have related subjects. So no need to show broken all link
                                    See https://uvaissues.atlassian.net/browse/MANU-7948
                                */}
                                {baseArgs?.baseType !== 'terms' && (
                                    <RelatedCount
                                        type={'all'}
                                        {...baseArgs}
                                        role={'tab'}
                                        className={locMatch['all']}
                                    />
                                )}
                                <RelatedCount
                                    type={'subjects'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['subjects']}
                                />
                                <RelatedCount
                                    type={'places'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['places']}
                                />
                                <RelatedCount
                                    type={'terms'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['terms']}
                                />
                                <RelatedCount
                                    type={'collections'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['collections']}
                                />
                                <RelatedCount
                                    type={'audio-video'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['audio-video']}
                                />
                                <RelatedCount
                                    type={'images'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['images']}
                                />
                                <RelatedCount
                                    type={'sources'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['sources']}
                                />
                                <RelatedCount
                                    type={'texts'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['texts']}
                                />
                                <RelatedCount
                                    type={'visuals'}
                                    {...baseArgs}
                                    role={'tab'}
                                    className={locMatch['visuals']}
                                />
                            </nav>
                        </>
                    )}
                </section>
                {/* Removed per https://uvaissues.atlassian.net/browse/MANU-7535 (07/18/23)
                {statePages.length > 0 && (
                    <section className="l-history__list__wrap">
                        <div className="u-related__list__header">
                            Recently Viewed
                        </div>
                        <HistoryViewer />
                    </section>
                )}
                /*}
                {/* <section className="l-related__tree__wrap">
                    <div className="u-related__list__header">
                        Browse{' '}
                        <span className={'text-capitalize'}>
                            {baseArgs.baseType}
                        </span>
                    </div>

                    <SideTree {...baseArgs} />
                </section> */}
            </div>
        </aside>
    );
}

function RelatedCount(props) {
    let count = props.relateds?.assets
        ? props.relateds.assets[props.type]?.count
        : 0;
    const augment = props?.augments[props.type] || 0;
    count += augment;
    // assign shanticon class according to type.  "all" type should get the "shanticon-logo-shanti" icon.

    const iconClass =
        'icon u-icon__' + (props.type === 'all' ? 'logo-shanti' : props.type);

    let display = 'deck';
    const listTypes = ['places', 'sources', 'subjects', 'texts', 'terms'];
    if (listTypes.includes(props.type)) {
        display = 'list';
    }
    if (props.type === 'images') {
        display = 'gallery';
    }

    // Link path.
    let path =
        '/' +
        props.baseType +
        '/' +
        props.id +
        '/related-' +
        props.type +
        '/' +
        /* Removing this code becuase I think '/any/' is no longer needed
        (props.baseType === 'terms' && props.type !== 'subjects'
           ? 'any/'
           : '') +
       */
        display;

    let activeClass = props.className || '';

    // return null if the count doesn't exist or is === 0
    return count ? (
        <Link
            id={'sui-rl-' + props.type}
            href="#"
            className={`nav-item nav-link c-related__item c-related__link--${props.type} ${activeClass}`}
            role={'tab'}
            to={path}
        >
            <span className={'u-icon__' + props.type + ' ' + iconClass}></span>
            <span className={'c-related__item__label'}> {props.type}</span>
            <span id="sui-rln-places">{count}</span>
        </Link>
    ) : null;
}

// eslint-disable-next-line no-unused-vars
function SideTree(props) {
    // Determine which tree (browse_tree) to display in the relateds sidebar
    const domain = props.baseType;
    const kid = props?.id ? props.id : false;
    const fid = kid ? queryID(domain, kid) : false;
    // places settings
    let level = false;
    let showAnc = false;

    // Subject settings
    if (domain === 'subjects') {
        level = 1;
        showAnc = false;
        // Term settings
    } else if (domain === 'terms') {
        level = 1;
        showAnc = false;
    }
    return (
        <KmapTree
            elid={`related-tree-left-${domain}`}
            className="l-relatedTreeLeft"
            domain={domain}
            selectedNode={fid}
            project={getProject()}
            showAncestors={showAnc}
            level={level}
        />
    );
}
