import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Nav, Tab, Form, Button } from 'react-bootstrap';
import { getSolrData, useSolr } from '../../../hooks/useSolr';
import MandalaSkeleton from '../../common/MandalaSkeleton';
import './TermsGlossary.scss';
import { useKmap } from '../../../hooks/useKmap';
import { useInfiniteQuery } from 'react-query';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/all';
import TermDictionaries from '../TermDictionaries';
import { usePerspective } from '../../../hooks/usePerspective';
import TermGlossarySearchBox from './TermGlossarySearchbox';
import * as conv from '../../TranslationTool/src/DevanagariConverter';

export default function TermGlossary({ lang }) {
    const { tid } = useParams();
    const history = useHistory();
    const startAt = tid || false;
    const [chosenLetter, setChosenLetter] = useState([333901, 'अ', 'a']);
    const setPerspective = usePerspective((state) => state.setPerspective);

    lang = lang === 'nod' ? 'new' : lang;
    // level_new.alpha_i or level_tib.alpha_i = 1 is first level in kmterms
    // do useSolr(query key, query object), query object has index: terms, params for query
    // Find all head letters to make TOC
    const startAtPosQuery = {
        index: 'terms',
        params: {
            q: `uid:terms-${startAt}`,
            fq: [`tree:terms`],
            rows: 1,
            fl: '*',
            sort: `position_i ASC`,
        },
    };

    let {
        isLoading: isSAPosLoading,
        data: sAPosData,
        isError: isSAPosError,
        error: sAPosError,
    } = useSolr(
        ['terms', 'glossary', lang, 'sapos', startAt],
        startAtPosQuery,
        false,
        false
    );

    const query = 'associated_subject_ids:10523'; // TODO: check if this is for all languages or just Newar. Does Tibetan use a different letter subject tag?

    // Find all head letters to make TOC
    let letterQuery = {
        index: 'terms',
        params: {
            q: query,
            fq: [`tree:terms`],
            rows: 100,
            fl: '*',
            sort: `position_i ASC`,
        },
    };

    let {
        isLoading: areLettersLoading,
        data: letterdata,
        isError: isLetterError,
        error: letterError,
    } = useSolr(
        ['terms', 'glossary', lang, 'letters'],
        letterQuery,
        false,
        false
    );

    useEffect(() => {
        if (lang === 'new') {
            setPerspective('terms', 'new.alpha');
        }
    }, []);

    useEffect(() => {
        if (tid && sAPosData) {
            const saentry = sAPosData.docs[0];
            const letid = saentry['ancestor_uids_new.alpha'][0];
            const letlat = saentry['ancestors_new.alpha'][0];
            const letdeva = conv.toDevanagari(letlat);
            setChosenLetter([letid, letdeva, letlat]);
        }
    }, [tid, sAPosData]);

    if (areLettersLoading || isSAPosLoading) {
        return <MandalaSkeleton />;
    }

    if (isLetterError) {
        return <div className="error red">{letterError}</div>;
    }

    if (isSAPosError) {
        return <div className="error red">{sAPosError}</div>;
    }

    const chooseLetterClick = (e) => {
        const lid = e.target.dataset.lid.replace('terms-', '');
        setChosenLetter(lid.split('|'));
        window.scrollTo(0, 0);
        if (startAt) {
            history.push('/terms/glossary/nod/');
        }
    };

    const saPos = sAPosData?.docs[0]?.cascading_position_i;

    // console.log('sa pos', saPos);

    return (
        <Container id="glossary">
            <Row>
                <Col>
                    <h2 className="text-center">
                        Glossary for{' '}
                        <span className="text-capitalize">
                            {lang === 'new' ? 'Newar' : 'Tibetan'}
                        </span>
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col id="letters" sm={3}>
                    <div className="wrapper">
                        <Form className="glossary-search-form d-flex p-2">
                            <TermGlossarySearchBox />
                        </Form>
                        <Nav variant="pills" className="letter-grid">
                            {letterdata.docs.map((letter, index) => {
                                const classes = [lang];
                                const lethead = letter?.header;
                                let hash = lethead
                                    .split('')
                                    .map((ch, chi) => {
                                        return ch.charCodeAt(0);
                                    })
                                    .join('');
                                // The long retroflex L letter cannot be used in css styles so changing to llr
                                const letclass =
                                    hash === '108803772' ? 'llr' : lethead;
                                if (letter.header === chosenLetter[2]) {
                                    classes.push('active');
                                }
                                return (
                                    <Nav.Item
                                        key={`letter-nav-${index}`}
                                        className={letclass}
                                    >
                                        <Nav.Link
                                            key={`letter-${letter.id}`}
                                            eventKey={letter.head}
                                            className={classes.join(' ')}
                                            data-lid={[
                                                letter.id,
                                                letter.name_deva[0],
                                                letter.header,
                                            ].join('|')}
                                            onClick={chooseLetterClick}
                                        >
                                            {letter.name_deva[0]}
                                        </Nav.Link>
                                    </Nav.Item>
                                );
                            })}
                        </Nav>
                    </div>
                </Col>
                <Col id="definitions">
                    {chosenLetter && (
                        <GlossarySection
                            letter={chosenLetter}
                            startAt={saPos}
                            key={`gloss-section-${chosenLetter.id}`}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    );
}

function GlossarySection({ letter = [-1, '', ''], startAt = false }) {
    const persp = usePerspective((state) => state.getPerspective('terms'));
    const [letterTid, letterName, letterHead] = letter;
    //const [entries, setEntries] = useState([]);

    const {
        data: sectionPages,
        error: isSectionError,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage: isLoading,
    } = useInfiniteQuery([letterTid, 'entries', persp, startAt], entriesQuery, {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage, allPages) => lastPage?.nextPage,
    });

    const observer = useRef();
    const lastItemRef = useCallback((node) => {
        if (isFetching) return;
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasNextPage) {
                fetchNextPage();
            }
        });
        if (node) {
            // console.log('setting observer');
            observer.current.observe(node);
        }
    });
    /*
    if (isLoading || isFetching) {
        return null;
    }*/
    const pages = sectionPages?.pages.map((pg, pid) => {
        const pgidat = pid === sectionPages.pages.length - 1 ? 'last' : pid;
        return (
            <div key={`gloss-pagekey-${pid}`} id={`gloss-page-${pgidat}`}>
                {pg.data.map((ent, ei) => (
                    <GlossaryEntry data={ent} key={`gloss-entry-${ent.id}`} />
                ))}
            </div>
        );
    });

    return (
        <div key={`container-letter-${letterTid}`}>
            <h2>
                Letter <span className="ne">{letterName}</span> ({letterHead})
            </h2>
            <div className="pl-5">{pages}</div>
            <div ref={lastItemRef}>Loading...</div>
        </div>
    );
}

const entriesPerPage = 100;
// The actual async query function to retrieve the entries to display
const entriesQuery = async ({ pageParam = 0, queryKey }) => {
    const [ltid, label, perspective, startAt] = queryKey;
    const query = startAt
        ? `cascading_position_i:[${startAt} TO *]`
        : `ancestor_ids_new.alpha:${ltid}`; //;
    const sectionQuery = {
        index: 'terms',
        params: {
            q: query,
            fq: [
                `tree:terms`,
                '-associated_subject_ids:10524',
                `level_${perspective}_i:*`,
            ],
            start: pageParam * entriesPerPage,
            rows: entriesPerPage,
            fl: '*,[child parentFilter=block_type:parent limit=1000]',
            sort: `cascading_position_i ASC`,
        },
    };
    const resp = await getSolrData(sectionQuery, false);
    return { data: resp?.docs, nextPage: pageParam + 1 };
};

function GlossaryEntry({ data }) {
    let [deva, latin, defs] = ['', '', []];
    if (data.associated_subjects[0] === 'Letter') {
        return null;
    } // Don't show letter entries
    data._childDocuments_.forEach((child, key) => {
        if (child.block_child_type === 'related_names') {
            if (child.related_names_writing_system_code_s === 'deva') {
                deva = child.related_names_header_s;
            } else if (child.related_names_writing_system_code_s === 'latin') {
                latin = child.related_names_header_s;
            }
        }
    });

    const definitions = data._childDocuments_.filter((cd) => {
        return cd.block_child_type === 'related_definitions';
    });
    const defsbysource = definitions.reduce((dbs, def) => {
        if (!dbs[def.related_definitions_source_code_s]) {
            // If it doesn't exist, create an empty array for this key
            dbs[def.related_definitions_source_code_s] = [];
        }
        // Push the current object to the corresponding array
        dbs[def.related_definitions_source_code_s].push(def);
        return dbs;
    }, {});
    // related_terms_relation_code_s
    const variants = data._childDocuments_
        .filter((cd) => {
            return cd.related_terms_relation_code_s === 'variant';
        })
        .filter(
            (cd, cdindex, self) =>
                cdindex === self.findIndex((doc) => doc.id === cd.id)
        ); // filters out duplicate objects

    return (
        <div
            key={`entrydiv-${data.id}`}
            className="glossary entry"
            data-kid={data.id}
        >
            <h3>
                <span className="ne">{deva}</span>{' '}
                <span className="en">({latin})</span>
                <Link
                    to={`/${data.id.replace('-', '/')}`}
                    className="gloss-entry-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="View in dictionary."
                >
                    <FaExternalLinkAlt />
                </Link>
            </h3>
            <TermDictionaries definitions={defsbysource} kmapData={data} />
            {variants?.length > 0 && (
                <div className="sui-termDicts__dict-variants">
                    <h5>Variants: </h5>
                    <ul className="sui-termDicts__dict-wrapper">
                        {variants.map((vdata, vi) => (
                            <li
                                className="sui-termDicts__dict"
                                key={`${data.id}-var-${vi}`}
                            >
                                <GlossaryVariant
                                    kid={vdata.related_terms_id_s}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

/*
{
    variants?.map((vdata, vi) => (
                    <li key={`${data.id}-var-${vi}`}>
                        {vdata?.related_terms_relation_label_s}
                        <GlossaryVariant kid={vdata.related_terms_id_s} />
                    </li>
                ))}
 */

function GlossaryVariant({ kid }) {
    const {
        isLoading: isAssetLoading,
        data: kmasset,
        isError: isAssetError,
        error: assetError,
    } = useKmap(kid, 'info');

    // console.log("kid: " + kid, kmasset);
    if (
        typeof kid !== 'string' ||
        !kmasset ||
        !kmasset?.name_deva ||
        !kmasset?.name_latin
    ) {
        return null;
    }

    const defs =
        kmasset?._childDocuments_?.length > 0
            ? kmasset._childDocuments_
                  .map((cd, cdi) => {
                      if (cd?.related_definitions_content_latinu) {
                          return cd.related_definitions_content_latinu;
                      }
                      return null;
                  })
                  .filter((cd) => {
                      return cd;
                  })
            : [];

    let tstr = defs.join('. ').replace(/\.\./g, '.').trim();
    tstr += ' (Click to view term in dictionary.)';

    // console.log('variant', kmasset, defs);
    /// console.log('title str', tstr);
    return (
        <Link
            to={'/' + kid.replace('-', '/')}
            target="_blank"
            rel="noopener noreferrer"
            title={tstr.trim()}
        >
            <span className="ne d-inline-block ml-2" data-mid={kid}>
                {kmasset?.name_deva[0]}
            </span>{' '}
            ({kmasset?.name_latin[0]})
        </Link>
    );
    //return <span>Hi</span>;
}
