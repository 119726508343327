import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useCatSearchStore } from '../hooks/useCatSearchStore';
import { useHistory } from 'react-router-dom';
import { getLangCodeForString } from '../common/utils';
import { convertWylie, isTib } from '../../views/common/utils';
import { useCookies } from 'react-cookie';
import { EwtsConverter } from '../../views/TranslationTool/src/EwtsConverter.mjs';

const target = document.getElementById('wpCatSearch');

export function WpCatSearch(props) {
    const history = useHistory();
    const search = useCatSearchStore(); // State hook for Catalog searches only
    const searchboxRef = useRef(null);
    const selectRef = useRef(null);
    const [coll, setColl] = useState('all');
    // const [searchStr, setSearchStr] = useState(null);
    const [searchBoxLang, setSearchBoxLang] = useState(null);
    // const [doSearch, setDoSearch] = useState(false);
    const ewtsConv = new EwtsConverter();
    const cookie_names = [
        'thlcat_convwyl',
        'thlcat_searchactive',
        'thlcat_searchquery',
    ];

    const [cookies, setCookie, removeCookie] = useCookies(cookie_names);
    /*
    useEffect(() => {
        // Once search string is set, perform the search (waits for wylie to be converted)
        const sval =
            searchStr?.bo?.length > 0 ? searchStr?.bo : searchStr?.wy || '';
        if (sval?.length > 0 && doSearch) {
            // console.log("sval", sval);
            search.setQuery(sval);
            if (coll !== 'all') {
                search.addFilter(`coll:${coll}`);
            }
            setCookie('thlcat_searchquery', sval);
            window.location.hash = `/catalog/search`; // Only in word press so use hash to redirect
        }
    }, [searchStr]);

 */

    const handleSubmit = () => {
        // Get the input value in the search text box
        const searchVal = searchboxRef?.current?.value
            ? searchboxRef.current.value.trim()
            : '';
        console.log('IN HANDLE submit', searchVal);
        // setDoSearch(true);
        if (searchVal?.length > 0) {
            const collparam = coll === 'all' ? '' : `&coll=${coll}`;
            if (!isTib(searchVal)) {
                const bo = ewtsConv.to_unicode(searchVal + ' ');
                searchboxRef.current.value = bo;
                searchboxRef.current.classList.add('bo');
                window.location.hash = `/catalog/search?q=${bo}${collparam}`;
            } else {
                window.location.hash = `/catalog/search?q=${searchVal}${collparam}`;
            }
        }
    };

    const clearInput = (e) => {
        e.preventDefault();
        document.getElementById('sui-search').value = '';
    };

    const checkLang = (e) => {
        const sval = searchboxRef?.current?.value;
        setSearchBoxLang(getLangCodeForString(sval));
    };

    const checkForEnter = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    const doSetColl = (e) => {
        const collval = selectRef.current?.value;
        setColl(collval);
    };

    const wpCatSearch = (
        <div id="wpCatSearch">
            <div className="sui-search1">
                <select id="coll-select" ref={selectRef} onChange={doSetColl}>
                    <option value="all">All Collections</option>
                    <option value="kt">Kangyur/Tengyur</option>
                    <option value="ngb">Nyingma Gyübum</option>
                    <option value="km">Kama Gyéba</option>
                    <option value="lccw">Longchenba Collected Works</option>
                    <option value="dkcw">Dilgo Khyentse Collected Works</option>
                </select>
                <input
                    type="text"
                    id="sui-search"
                    className={`sui-search2 ${searchBoxLang}`}
                    placeholder="Search &amp; Explore!"
                    onChange={checkLang}
                    onKeyUp={checkForEnter}
                    ref={searchboxRef}
                />

                <span
                    id="sui-clear"
                    className="sui-search3"
                    onClick={clearInput}
                >
                    {' '}
                </span>
                <span
                    id="sui-searchgo"
                    className="sui-search4"
                    onClick={handleSubmit}
                >
                    <span className={'icon shanticon-magnify'}></span>
                </span>
            </div>
        </div>
    );

    if (target) {
        return ReactDOM.createPortal(wpCatSearch, target);
    } else {
        return null; // Don't add unless there is a target in the WP page
    }
}
