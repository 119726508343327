import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { MandalaPopover } from '../common/MandalaPopover';
import { useParams } from 'react-router-dom';
import { useKmap } from '../../hooks/useKmap';
import { getSolrCitation, queryID } from '../common/utils';
import GenericPopover from '../common/GenericPopover';
import MandalaSkeleton from '../common/MandalaSkeleton';

export default function TermsRelatedSubjectsViewer({ kid }) {
    const baseType = 'terms';

    const {
        isLoading: isKmapLoading,
        data: kmap,
        isError: isKmapError,
        error: kmapError,
    } = useKmap(queryID(baseType, kid), 'info');

    if (isKmapLoading) {
        return <MandalaSkeleton />;
    }
    const assocsubjs = kmap?.associated_subjects?.filter(
        (subj, si) => kmap.associated_subject_ids[si] !== 9315
    );
    const assocsubjids = kmap?.associated_subject_ids?.filter(
        (sid, sidind) => sid !== 9315
    );
    return (
        <div className={'terms--related-subjects'}>
            {assocsubjs?.length > 0 && (
                <ul>
                    {assocsubjs.map((subj, si) => {
                        const ky = ['terms', kid, 'subjects', si];
                        return (
                            <li key={ky}>
                                {subj}{' '}
                                <MandalaPopover
                                    domain="subjects"
                                    kid={assocsubjids[si]}
                                />{' '}
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
}
