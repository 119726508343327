import { Link, useParams } from 'react-router-dom';
import React from 'react';
import { biblSectionTypes } from './BiblRecord';
import { useBiblStore } from '../hooks/useBiblStore';
import { useCatSearchStore } from '../hooks/useCatSearchStore';
import { useCatSettings } from '../hooks/useCatSettings';

export function BiblNav({ sections, selkey, setSelected }) {
    let { coll, ed, txtnum } = useParams(); //:coll/:ed/:txtnum

    /* console.log("path is:", path);
    console.log(`Params: coll=${coll}, ed=${ed}, txtnum=${txtnum}`);
     */
    const bibl = useBiblStore((state) => state.doc);
    const searchActive = useCatSearchStore((state) => state.isActive);
    const { targString } = useCatSettings((state) => state);

    // Bibl Record Sections Click Function
    const clickMe = (evt) => {
        evt.preventDefault();
        const el = evt.target;
        setSelected(el.dataset.divkey);
        return false;
    };
    const allclass = selkey === 'all' ? 'selected' : '';
    const reader_base = `/catalog/${coll}/${ed}/${txtnum}`; // the base path to the current text being viewed
    const intertext_base = `${process.env.REACT_APP_ITX_HOME}${process.env.REACT_APP_TLA_PATH}__ID__/?targets=${targString}&mode=view#/`;

    // In Digital Text View, one can toggle with scans, if they exist
    let text_link = null;
    let intertext_link = null;
    if (bibl?.hastext) {
        const slug = bibl?.texttype === 'styled' ? 'text' : 'pages';
        text_link = (
            <li>
                <Link to={`${reader_base}/${slug}`}>Digital Text</Link>
            </li>
        );
        const myil = intertext_base.replace('__ID__', bibl.id);
        intertext_link = (
            <li>
                <a href={myil} target="_blank">
                    Intertextuality
                </a>
            </li>
        );
    } else if (bibl?.hasscans) {
        // If there are only scans and no digital text, we need to still add a link but to just scans
        text_link = (
            <li>
                <Link to={`${reader_base}/scans`}>Page Images</Link>
            </li>
        );
    }

    const search_link = searchActive ? (
        <li>
            <Link to="/catalog/search">Back to Search Results</Link>
        </li>
    ) : null;

    return (
        <div className="c-bibl-nav">
            <h3>Sections</h3>
            <ul className="list-unstyled">
                <li key="pg-toc-key-all" className={allclass}>
                    <a data-divkey="all" onClick={clickMe} href="#">
                        All
                    </a>
                </li>
                {Object.keys(sections).map((kn, i) => {
                    const k = sections[kn];
                    const v = biblSectionTypes[k];
                    const myclass = k === selkey ? 'selected' : '';
                    return (
                        <li
                            key={`pg-toc-key-${i}`}
                            className={myclass}
                            id={`${k}-link`}
                        >
                            <a data-divkey={k} onClick={clickMe} href="#">
                                {v}
                            </a>
                        </li>
                    );
                })}
                {text_link}
                {intertext_link}
                {search_link}
            </ul>
        </div>
    );
}
