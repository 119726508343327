import React, { useEffect, useState } from 'react';
import { FeatureCollection } from '../common/FeatureCollection';
import { useParams } from 'react-router-dom';
import MandalaSkeleton from '../common/MandalaSkeleton';
import { useSolr } from '../../hooks/useSolr';
import { MandalaPopover } from '../common/MandalaPopover';
import { EwtsConverter } from '../TranslationTool/src/EwtsConverter.mjs';

export function TermsRelatedTerms({ term }) {
    const { viewMode } = useParams();

    const [perPage, setPerPage] = useState(50); // These are the rows returned
    const [page, setPage] = useState(0); // Start will always be page * perPage
    const start_row = page * perPage;
    const isTib = term['level_tib.alpha_i'] > 0;
    const ewts = new EwtsConverter();
    let relterms = term._childDocuments_.filter(
        (cd) =>
            cd?.block_child_type === 'related_terms' &&
            cd?.related_terms_relation_code_s !== 'heads'
    );
    // Remove duplicate children (from Chat GPT)
    relterms = Object.values(
        relterms.reduce((acc, obj) => {
            acc[obj.id] = obj;
            return acc;
        }, {})
    );
    return (
        <div className={'terms--related-subjects'}>
            <ul>
                {relterms.map((relterm, rti) => {
                    const ky = ['terms', term.uid, 'terms', rti];
                    let head = relterm?.related_terms_header_s;
                    if (isTib) {
                        head = ewts.to_unicode(head);
                    }
                    return (
                        <li key={ky} className="bo">
                            {head}{' '}
                            <MandalaPopover
                                domain="terms"
                                kid={relterm?.related_terms_id_s?.replace(
                                    'terms-',
                                    ''
                                )}
                            />{' '}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
