import React, { useEffect, useRef, useState } from 'react';
import { useBiblStore } from '../hooks/useBiblStore';
import { Button, Form } from 'react-bootstrap';
import { useCatSearchStore } from '../hooks/useCatSearchStore';
import { Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import jsonpAdapter from '../common/axios-jsonp';
import { useCookies } from 'react-cookie';
import { IoArrowBack } from 'react-icons/all';
import { getLangCodeForString } from '../common/utils';
import { convertWylie, isTib } from '../../views/common/utils';
import { EwtsConverter } from '../../views/TranslationTool/src/EwtsConverter.mjs';

export function NavBasicSearch() {
    const search = useCatSearchStore();
    const [disabled, setDisabled] = useState(false);
    // When searchstr state is changed, the search is initiated
    const [searchStr, setSearchStr] = useState(null);
    const [doSearch, setDoSearch] = useState(false);
    // const [doRedirect, setRedirect] = useState(false); // state to trigger redirect to search page
    // const [convertWylie, setConvertWylie] = useState(false);
    const defaultLang = search?.query?.length > 0 ? search.queryLang : 'en';
    const [searchBoxLang, setSearchBoxLang] = useState(defaultLang);

    let onSearchPage = useRouteMatch('/catalog/search');
    const history = useHistory();
    const searchBox = useRef(); // Reference to html text input where search string is entered

    const ewtsConv = new EwtsConverter();
    //  console.log('on search page', onSearchPage);

    // const wyliecbRef = useRef();
    const cookie_names = [
        'thlcat_convwyl',
        'thlcat_searchactive',
        'thlcat_searchquery',
    ];
    const [cookies, setCookie, removeCookie] = useCookies(cookie_names);

    const submitSearch = (e) => {
        if (e) {
            e.preventDefault();
        }
        console.log('setting search string from basic form', searchStr);
        search.setQuery(searchStr);
        history.push('/catalog/search');
    };

    const checkKeyUp = (e) => {
        const sval = searchBox?.current?.value;
        setSearchBoxLang(getLangCodeForString(sval));
        if (e.key === 'Enter') {
            e.preventDefault();
            checkWylie();
            submitSearch(e);
        }
    };

    const clearForm = () => {
        setSearchStr(null);
        if (searchBox?.current) {
            searchBox.current.value = '';
            searchBox.current.classList.remove('bo');
        }
    };

    const checkWylie = () => {
        const inval = searchBox?.current?.value.trim();
        if (search?.convWy) {
            if (inval?.length > 0) {
                if (!isTib(inval) && search.convWy) {
                    const bo = ewtsConv.to_unicode(inval + ' ');
                    searchBox.current.value = bo;
                    searchBox.current.classList.add('bo');
                    setSearchStr(bo);
                } else if (isTib(inval)) {
                    searchBox.current.classList.add('bo');
                }
            }
        } else {
            setSearchStr(inval);
        }
    };

    useEffect(() => {
        if (!search?.isAdvanced) {
            if (search?.query && search.query !== '*:*') {
                searchBox.current.value = search.query;
                if (isTib(search.query)) {
                    searchBox.current.classList.add('bo');
                }
            } else {
                searchBox.current.value = '';
                searchBox.current.classList.remove('bo');
            }
        }
    }, [search]);

    // When the search string state is changed, initiate the search, redirect to search page if not on it
    /*
    useEffect(() => {
        if (!searchStr) {
            checkWylie();
        }
        if (doSearch) {
            if (searchStr?.bo) {
                search.setQuery(searchStr.bo);
                setCookie('thlcat_searchquery', searchStr.bo);
            } else if (searchStr?.wy) {
                search.setQuery(searchStr.wy);
                setCookie('thlcat_searchquery', searchStr.wy);
            } else {
                console.log(
                    'No search string',
                    searchStr,
                    searchBox.current.value
                );
            }

            setCookie('thlcat_searchactive', true);
            history.push('/catalog/search');
        }
        return () => {
            setDoSearch(false);
        };
    }, [searchStr, doSearch]);

     */

    useEffect(() => {
        if (search?.isAdvanced) {
            clearForm();
        }
    }, [search?.isAdvanced]);

    const onSearchClass = onSearchPage && search?.showing ? ' search-page' : '';

    return (
        <Form className="nav-search-form d-flex p-2" onSubmit={submitSearch}>
            <div className={`search-and-back${onSearchClass}`}>
                <Form.Control
                    type="search"
                    placeholder="Search & Explore!"
                    className={`me-2 cat-search ${searchBoxLang}`}
                    aria-label="Search"
                    ref={searchBox}
                    onKeyUp={checkKeyUp}
                    disabled={disabled}
                    onBlur={checkWylie}
                />
                {/*  onBlur={setInputVal} */}

                <Button
                    variant="success"
                    onClick={submitSearch}
                    className="submit-btn"
                    disabled={disabled}
                >
                    <strong>Search</strong>
                </Button>
            </div>
            {search.isActive && !onSearchPage && (
                <div className={`back`}>
                    <Link to="/catalog/search" title="Back to results page">
                        Back to search results
                    </Link>
                </div>
            )}
        </Form>
    );
}
