import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useCatSearchStore } from '../hooks/useCatSearchStore';
import { Link, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { BiXCircle, BsQuestionCircleFill, FaInfoCircle } from 'react-icons/all';
import { NavBasicSearch } from './NavBasicSearch';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { getAllLangs, getLangFromString } from '../common/catalogUtils';
import { useBiblStore } from '../hooks/useBiblStore';
import { isTib } from '../../views/common/utils';
import { EwtsConverter } from '../../views/TranslationTool/src/EwtsConverter.mjs';

export function NavSearch() {
    const bibl = useBiblStore();
    const search = useCatSearchStore();
    const ewtsConv = new EwtsConverter();

    // The rows in the form
    const [docType, setDocType] = useState('all');
    const [docScope, setDocScope] = useState('all');
    const [formRows, setFormRows] = useState([]);
    const [searchQuery, setSearchQuery] = useState(false);

    const history = useHistory();

    //const seachboxRef = useRef(); // Reference to html text input where search string is entered

    /*const cookie_names = [
        'thlcat_convwyl',
        'thlcat_searchactive',
        'thlcat_searchquery',
    ];
    const [cookies, setCookie, removeCookie] = useCookies(cookie_names);*/
    // console.log("Bibl in search form", bibl);

    const doSubmit = (e) => {
        e.preventDefault();
        const lri = formRows.length - 1;
        const lastrow = formRows[lri];
        if (search.convWy && !isTib(lastrow?.query && ewtsConv)) {
            const bo = ewtsConv.to_unicode(lastrow.query);
            rowChanged(lri, 'query', bo);
        }
        const dtype = docType?.toLowerCase(); // Type of SOLR docs to search
        const dscope = docScope?.toLowerCase(); // Scope of SOLR docs to search: ed, coll, all
        search.resetFilters();
        let newQuery = '*:*';
        // Set Scope filters
        switch (dscope) {
            case 'ed':
                // console.log("in ed", bibl);
                if (bibl?.coll) {
                    search.addFilter(`coll:${bibl.coll}`);
                }
                if (bibl?.ed) {
                    search.addFilter(`edsig:${bibl.ed}`);
                }
                break;
            case 'coll':
                if (bibl?.coll) {
                    search.addFilter(`coll:${bibl.coll}`);
                }
                break;
            default:
                break;
        }
        // Set type filters
        if (dtype === 'text') {
            search.addFilter('type:textpg');
        } else if (dtype === 'bibl') {
            search.addFilter('type:tibbibl');
        }

        //console.log('Setting Advanced data', formRows);
        search.setAdvancedData(dtype, dscope, formRows);
        search.setHighlighting('title,agent,id,doxcat');
        setSearchQuery(search.query);
        history.push('/catalog/search'); // Comment out for debugging
    };

    const submitAdvSearch = (e) => {
        e.preventDefault();
        search.resetFilters(); // Clear default or old filters
        search.setActive(true);
        search.setIsAdvanced(true); // set advanced search to true
        const lri = formRows.length - 1;
        const lastrow = formRows[lri];
        if (search.convWy && !isTib(lastrow?.query && ewtsConv)) {
            const bo = ewtsConv.to_unicode(lastrow.query);
            rowChanged(lri, 'query', bo);

            doSubmit(e);
        } else {
            doSubmit(e);
        }
    };

    const newRow = () => {
        return {
            id: Math.random(), // To ensure redraw when reset
            op: 'and',
            query: '',
            field: 'all',
        };
    };

    const rowChanged = (i, f, v) => {
        // console.log('in row changed', i, f, v);
        const newRows = formRows;
        if (f === 'del') {
            newRows.splice(i, 1);
            // console.log("new rows after del", newRows);
        } else {
            newRows[i][f] = v;
        }
        setFormRows([...newRows]);
    };

    const addRow = () => {
        const newFormRows = formRows.concat([newRow()]);
        setFormRows(newFormRows);
    };

    const resetForm = (resetStore = true) => {
        if (resetStore) {
            search.reset();
        }
        const anewrow = [newRow()];
        setFormRows([...anewrow]);
    };

    // useEffect to monitor search state
    useEffect(() => {
        const newDocType = search?.advancedData?.docType;
        if (newDocType !== docType) {
            setDocType(newDocType);
        }
        const newDocScope = search?.advancedData?.docScope;
        if (newDocScope !== docScope) {
            setDocScope(newDocScope);
        }
        const newQuery = search?.query;
        if (newQuery !== searchQuery) {
            setSearchQuery(newQuery);
        }
        if (!search.isAdvanced) {
            setFormRows([]);
        }
    }, [search.query]);

    // OnLoad useEffect and whenever reset
    useEffect(() => {
        if (formRows.length === 0) {
            // Add first empty row to row list
            addRow();
        }
    }, [formRows]);

    useEffect(() => {
        const sad = search?.advancedData;
        if (sad) {
            if (sad?.docType && sad?.docType !== docType) {
                console.log('setting doctype', sad.docType);
                setDocType(sad.docType);
            }
            if (sad?.docScope && sad?.docScope !== docScope) {
                setDocScope(sad.docScope);
            }
            if (sad?.formRows) {
                setFormRows(sad.formRows);
            }
        }
    }, [search?.advancedData]);

    return (
        <>
            <NavTranslitToggle />
            <div id="cat-basic-search">
                <h3>Basic Search</h3>
                <NavBasicSearch />
            </div>
            <hr />
            <div id="cat-adv-search">
                <h3>Advanced Search</h3>
                <Form
                    id="adv-search-form"
                    className="nav-search-form p-2 lh-25"
                    /*onSubmit={submitAdvSearch}*/
                >
                    <span className="d-inline">Search </span>
                    <Form.Group className="d-inline">
                        <Form.Control
                            as="select"
                            className="d-inline col-md-2 pr-3"
                            onChange={(e) => setDocType(e.target.value)}
                            defaultValue={docType}
                        >
                            <option value="all">All</option>
                            <option value="bibl">Catalog</option>
                            <option value="texts">Text</option>
                        </Form.Control>
                        <span className="d-inline"> documents for </span>
                    </Form.Group>
                    <Form.Group className="element-invisible">
                        <span> in </span>
                        <Form.Control
                            as="select"
                            className="d-inline col-md-3 pr-2"
                            onChange={(e) => setDocScope(e.target.value)}
                            /*defaultValue={docScope} */
                            defaultValue="all"
                        >
                            {bibl?.ed && (
                                <option value="ed">This Edition</option>
                            )}
                            {bibl?.coll && (
                                <option value="coll">This Collection</option>
                            )}
                            <option value="all">All Collections</option>
                        </Form.Control>
                        <span> for </span>
                    </Form.Group>
                    {formRows.map((rw, ri) => {
                        return (
                            <SearchFormRow
                                key={['sfr', rw?.id, ri]}
                                ind={ri}
                                totalRows={formRows.length}
                                datain={rw}
                                doctype={docType}
                                change={rowChanged}
                                addRow={addRow}
                                isLast={ri === formRows.length - 1}
                            />
                        );
                    })}

                    <div className="mt-4 d-flex justify-content-around">
                        <div className="flex-fill"></div>
                        <Button
                            onClick={doSubmit}
                            className="submit-btn btn btn-lg btn-success"
                        >
                            <strong>Search</strong>
                        </Button>
                        <div className="flex-fill"></div>
                        <Button
                            type="reset"
                            onClick={resetForm}
                            className="reset-btn btn btn-lg btn-secondary"
                        >
                            <strong>Reset</strong>
                        </Button>
                        <div className="flex-fill"></div>
                    </div>

                    {search?.debug === true && (
                        <div id="search-query">
                            <h3>Search Query</h3>
                            <pre>
                                {(searchQuery && JSON.stringify(searchQuery)) ||
                                    (!searchQuery && 'No query yet.')}
                            </pre>
                            <pre>{JSON.stringify(search.solrQuery)}</pre>
                        </div>
                    )}
                    {search.isActive && (
                        <div className={`back`}>
                            <Link
                                to="/catalog/search"
                                title="Back to results page"
                            >
                                Back to search results
                            </Link>
                        </div>
                    )}
                </Form>
            </div>
        </>
    );
}

/**
 * SearchFormRow: A component that represents a row in the search form
 * A row consists of a text field for the query string, a select for the field(s) to query, a select for the type
 * of documents to query, and a select for the scope of the query (all, collection, edition). When created, it
 * takes and integer prop that represents the row of the data array (starting with 0) which is contained in the
 * form component above. In that array, each row is represented by an object as follows:
 *    {
 *        query: (the string to search for),
 *        field: (the option value for field select),
 *        docs: (the option value for the document type select),
 *        scope: (the option value for the collection/edition select)
 *    }
 * @param rind - the row index in the forms data array
 * @param datain - the initial values for each control item
 * @param onchange - a function that takes row index, the param name, and the new value for that param
 * @returns {JSX.Element}
 * @constructor
 */
function SearchFormRow({
    ind,
    totalRows,
    datain,
    doctype = 'all',
    change,
    isLast,
    addRow,
}) {
    // console.log("in search form row component", datain);
    const { op, query, field } = datain;
    const currlang = getLangFromString(query);
    const search = useCatSearchStore();
    const ewtsConv = new EwtsConverter();
    // Internal functions
    const checkLang = (targ) => {
        getAllLangs().forEach((l) => {
            targ.classList.remove(l); // Remove any existing lang class
        });
        const langclass = getLangFromString(targ.value);
        targ.classList.add(langclass); // Add current lang class
    };

    const checkWylie = (ind) => {
        const infield = document.getElementById(`cat-sr-${ind}-text`);
        const inval = infield.value;
        if (inval?.length > 0 && !isTib(inval) && search?.convWy) {
            const bo = ewtsConv.to_unicode(inval);
            if (bo?.length > 0) {
                infield.value = bo;
                infield.classList.add('bo');
                change(ind, 'query', bo);
            } else {
                change(ind, 'query', inval);
            }
        }
    };

    const operator =
        ind > 0 ? (
            <Form.Control
                as="select"
                className="d-inline col-md-2 p-1"
                defaultValue={op}
                onChange={(e) => {
                    change(ind, 'op', e.target.value);
                }}
            >
                <option value="AND">and </option>
                <option value="OR">or</option>
            </Form.Control>
        ) : null;

    return (
        <div className={`mb-2 row-${ind}`} id={`cat-search-row-${ind}`}>
            {' '}
            {operator}
            <Form.Control
                type="search"
                id={`cat-sr-${ind}-text`}
                placeholder="...enter search phrase here..."
                className={`cat-search d-inline col-md-6 ${currlang}`}
                defaultValue={query}
                onChange={(e) => {
                    const target = e.target;
                    change(ind, 'query', target.value);
                    checkLang(target); // Adds lang class to input
                }}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        checkWylie(ind);
                    }
                }}
                onBlur={() => checkWylie(ind)}
            />
            {/* <Form.Label className='row-form-label pr-1'>Scope</Form.Label> */}
            {doctype.toLowerCase() === 'bibl' && (
                <Form.Group id={`ffields-${ind}`} className="d-inline">
                    <span> in </span>
                    <Form.Control
                        as="select"
                        className="d-inline col-md-3 pr-2"
                        defaultValue={field}
                        onChange={(e) => {
                            change(ind, 'field', e.target.value);
                        }}
                    >
                        <option value="all">All Fields</option>
                        <option value="agent">People’s Names</option>
                        <option value="title">Titles</option>
                        <option value="chaptitle">Chapter Titles</option>
                        <option value="doxcat">Categories</option>
                        <option value="colophon">Colophons</option>
                        <option value="id">IDs</option>
                    </Form.Control>
                </Form.Group>
            )}
            {(ind > 0 || totalRows > 1) && (
                <Form.Group
                    id={`f-row-rm-${ind}`}
                    className="d-inline-block ml-2"
                >
                    <a className="link" onClick={(e) => change(ind, 'del')}>
                        {' '}
                        <BiXCircle />{' '}
                    </a>
                </Form.Group>
            )}
            {isLast && (
                <div className="d-inline-block ml-4">
                    <Button
                        onClick={addRow}
                        className="addrow-btn btn btn-sm btn-info"
                    >
                        <strong>Add Row</strong>
                    </Button>
                </div>
            )}
        </div>
    );
}

export function buildAdvQuery(formRows, docType) {
    //  console.log("Building adv query", formRows, docType);
    let qstr = '';
    if (docType?.toLowerCase() === 'text') {
        // For text queries, search in tibetan pages (for now) but need to generalize
        formRows.forEach((rw, ri) => {
            let conn = ri > 0 ? ` ${rw['op'].toUpperCase()} ` : '';
            qstr += `${conn}${rw['query']}`;
        });
        qstr = `pg_bo:(${qstr})`;
    } else if (docType?.toLowerCase() === 'bibl') {
        // For searching bibls, parse each row for field based queries
        const qparts = formRows.map((rw, i) => {
            //console.log(`row ${i}:`, rw);
            const conn = i > 0 ? ` ${rw['op'].toUpperCase()} ` : '';
            let prow = parseRow(rw);
            return `${conn}(${prow})`;
        });
        //console.log('qparts:', qparts);
        qstr = qparts.join('');
    } else {
        // When scope "All" is chosen, just search in generic SOLR text field
        formRows.forEach((rw, ri) => {
            // console.log(rw, rw['op'].toUpperCase());
            let conn = ri > 0 ? ` ${rw['op'].toUpperCase()} ` : '';
            qstr += `${conn}"${rw['query']}"`;
        });
        return qstr; // `text:(${qstr})`;
    }
    //console.log("qstr", qstr);
    return qstr;
}

export function parseRow(rw) {
    // console.log('row in parse row', rw);
    let rqv = rw['query'].trim(); // Row query value
    switch (rw['field']) {
        case 'title':
            rqv = rqv.includes('"') ? rqv : `"${rqv}"`;
            return `{!parent which="type:tibbibl"}title_bo:${rqv}`;

        case 'agent':
            // rqv = rqv.includes('*') ? rqv : rqv + '*'; // asterisk breaks it actually
            return `agent:"${rqv}"`;

        case 'chaptitle':
            rqv = rqv.includes('"') ? rqv : `"${rqv}"`;
            return `type:chapter AND title:${rqv}`;

        case 'id':
            rqv = rqv.toLowerCase();
            // If Id is given as "Kt.D.12" format, then convert to kt-d-0012
            if (rqv.includes('.')) {
                const qpts = rqv.split('.');
                let lst = qpts.pop();
                if (!isNaN(lst)) {
                    lst = lst.padStart(4, '0');
                    rqv = qpts.join('-') + `-${lst}`;
                }
            }
            return `id:${rqv}*`;

        case 'doxcat':
            // rqv = rqv.includes('"') ? rqv : `"${rqv}"`;
            return `type:doxcat AND title:${rqv}*`;

        case 'colophon':
            return `colophon:${rqv}`;

        default:
            // console.log(`default catching: ${rw['field']}`);
            return `"${rqv}"`;
    }
}

function NavTranslitToggle() {
    const search = useCatSearchStore();
    const renderTooltip = (props) => {
        console.log(props);
        return (
            <Tooltip id="wytooltip" className="wylie-tooltip" {...props}>
                If checked, all search phrases entered in Latin script will be
                converted to Unicode Tibetan.
                <br />
                <strong>Note:</strong> Transliteration must be according to
                THL’s extended Wylie system.
            </Tooltip>
        );
    };
    return (
        <div className="d-flex justify-content-end">
            <Form.Group id="wyl-check" className="d-inline text-align-right">
                <OverlayTrigger
                    placement="left" // Adjust the placement as needed (top, bottom, left, right)
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >
                    <span className="tt-icon">
                        <FaInfoCircle size={16} />
                    </span>
                </OverlayTrigger>
                <Form.Label htmlFor="wylieCheck">Convert Wylie</Form.Label>
                <Form.Check
                    className="cat-check d-inline"
                    type="checkbox"
                    id="wylieCheck"
                    inline
                    defaultChecked
                    onChange={(e) => {
                        search.setConvWy(
                            document.getElementById('convertWylie').checked
                        );
                    }}
                />
                {/*
            <div className="tooltip-wrapper">
                <BsQuestionCircleFill className="tooltip-icon" />
                <div className="helptext tooltip left">
                    If checked, all search phrases entered in Latin
                    script will be converted to Unicode Tibetan.
                    <br/>
                    <strong>Note:</strong> Transliteration must be
                    according to THL’s extended Wylie system.
                </div>
            </div> */}
            </Form.Group>
        </div>
    );
}

/**
 * {
 *             op: 'and',
 *             query: '',
 *             field: 'all'
 *         };
 */
