import React, { useEffect, useState } from 'react';
import { FeatureCollection } from '../views/common/FeatureCollection';
// import { useFilterStore } from '../hooks/useFilterStore';
import { useRecentSearch } from '../hooks/useRecentSearch';
import { useSearch } from '../hooks/useSearch';
import { useParams } from 'react-router-dom';
import {
    useQueryParams,
    StringParam,
    withDefault,
    NumberParam,
} from 'use-query-params';
import { ArrayOfObjectsParam } from '../hooks/utils';
import MandalaSkeleton from '../views/common/MandalaSkeleton';
import { openTabStore } from '../hooks/useCloseStore';
import { browseSearchToggle } from '../hooks/useBrowseSearchToggle';
import { HIDE_SIDEBAR_WIDTH, PHONE_WIDTH } from '../App';
import { useAdvancedSearch } from '../hooks/useAdvancedSearch';
import { SearchBuilder } from '../views/AdvancedSearch/SearchBuilder';
import { KM_REL_REGEX, useKmapRelatedIDs } from '../hooks/useKmapRelatedIDs';

export function SearchViewer() {
    const { viewMode } = useParams();

    const setOpenTab = openTabStore((state) => state.changeButtonState);
    const setSearch = browseSearchToggle((state) => state.setSearch);
    const storedRows = useAdvancedSearch((state) => state.rows);

    // eslint-disable-next-line no-unused-vars
    const [query, setQuery] = useQueryParams({
        searchText: StringParam,
        filters: withDefault(ArrayOfObjectsParam, []),
    });
    const addSearchPage = useRecentSearch((state) => state.addSearchPage);
    let { searchText: search, filters, searchListIndex: sli } = query;
    // console.log("search entered is: " + search);

    const [searchWithRelated, setSearchWithRelated] = useState(search);
    const [perPage, setPerPage] = useState(50); // These are the rows returned
    const [page, setPage] = useState(0); // Start will always be page * perPage
    const start_row = page * perPage;

    const {
        isLoading: isKmRelLoading,
        data: kmRelData,
        isError: isKmRelError,
        error: kmRelError,
    } = useKmapRelatedIDs(search);

    // THE SEARCH QUERY
    const {
        isLoading: isSearchLoading,
        data: searchData,
        isError: isSearchError,
        error: searchError,
        isPreviousData,
    } = useSearch(
        searchWithRelated,
        start_row,
        perPage,
        'none',
        0,
        0,
        true,
        filters
    );

    useEffect(() => {
        if (storedRows?.length > 0) {
            const srchBuilder = new SearchBuilder(storedRows);
            const advquery = {
                searchText: srchBuilder.describeQuery(),
                filters: query[filters],
                data: storedRows,
            };
            addSearchPage(advquery);
        } else {
            // console.log('stored rows in search viewer', storedRows);
        }
        window.mandala.scrolledToSel = false;
    }, [query]);

    useEffect(() => {
        if (kmRelData?.tree) {
            const srchdocs = `(${kmRelData?.kmids?.join(' ')})`;
            let newsearch = search.replace(
                KM_REL_REGEX,
                `kmapid_strict:${srchdocs}`
            );
            setSearchWithRelated(newsearch);
        } else {
            setSearchWithRelated(search);
        }
        //console.log('search with related', searchWithRelated);
    }, [kmRelData]);

    /*
    useEffect(() => {
        console.log('searchWithRelated in SearchViewer', searchWithRelated);
    }, [searchWithRelated]);
     */

    useEffect(() => {
        // Open the search tab
        const otval = window.innerWidth < HIDE_SIDEBAR_WIDTH ? 0 : 1;
        setOpenTab(otval);
        setSearch();
    }, []);

    if (isSearchLoading || isKmRelLoading) {
        return (
            <div>
                <MandalaSkeleton />
            </div>
        );
    }

    if (isSearchError) {
        return (
            <div>
                <span>Error: {searchError.message}</span>
            </div>
        );
    }

    window.mandala.scrolledToSel = false;
    const docs = searchData.response?.docs ?? [];
    const numFound = searchData.response?.numFound ?? 0;
    let output = (
        <FeatureCollection
            docs={docs}
            viewMode={viewMode}
            inline={false}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            isPreviousData={isPreviousData}
            assetCount={numFound}
            showSearchFilters={true}
            hasMore={numFound <= page * perPage + perPage ? false : true}
        />
    );
    return output;
}
