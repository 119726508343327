import React, { useEffect } from 'react';
import { useBiblStore } from './hooks/useBiblStore';
import { useParams } from 'react-router-dom';
import { loadBibl } from './common/solrUtils';
import BiblRecord from './biblrecord/BiblRecord';
import TextReader from './reader/TextReader';
import { DoxRecord } from './catnav/DoxRecord';
import { useCatSearchStore } from './hooks/useCatSearchStore';

export function FrameContents(props) {
    const edBibl = useBiblStore((state) => state.edBibl);
    const setEdBibl = useBiblStore((state) => state.setEdBibl);
    const bibl = useBiblStore((state) => state.doc);
    const setBibl = useBiblStore((state) => state.setDoc);
    const setColl = useBiblStore((state) => state.setColl);
    const search = useCatSearchStore();

    const { coll, ed, txtnum, dox, vol, page, sid, view } = useParams();
    // console.log("params in catalog frame", coll, ed, txtnum, vol);
    const allparams = useParams();

    useEffect(() => {
        setColl(coll);
        if (coll && ed) {
            const promise = loadBibl(coll, ed).then((data) => {
                setEdBibl(data?.docs[0]);
            });
        }
    }, [coll, ed]);

    useEffect(() => {
        if (coll && ed && edBibl) {
            // console.log("in use effect", edBibl);
            let bnum = vol && !txtnum ? vol : txtnum;
            let bibtype = vol && !txtnum ? 'vol' : 'text';
            if (dox && dox?.length > 0) {
                bibtype = 'dox';
                bnum = dox;
            }
            const promise = loadBibl(coll, ed, bnum, bibtype).then((data) => {
                // console.log("data in load Bibl promise", data);
                setBibl(data?.docs[0]);
            });
        }
    }, [coll, ed, txtnum, dox, vol, edBibl]);

    /*
    useEffect(() => {
        if (search.isActive) {
            // console.log('Search active. Query is:', search.query);
            const contentarea = document.querySelectorAll('.l-frame-content');
            console.log('content area node', contentarea);
            if (contentarea?.length > 0) {
                setTimeout(() => {
                    search.highlightSearch(contentarea[0], search.query);
                }, 3000);
            }
        }
    }, [search?.isActive]);

     */

    let content = <div>Loading ... </div>;
    if (['text', 'pages', 'scans'].includes(view)) {
        /*if (CFDBUG) {
            console.log('Showing Text Reader');
        }
        catSearch.isNew = false;

         */

        content = (
            <TextReader viewMode={view} vol={vol} page={page} sid={sid} />
        );

        // Default is the current Bibl Record
    } else if (bibl?.type === 'tibbibl') {
        content = <BiblRecord />;
    } else if (bibl?.type === 'doxcat') {
        content = <DoxRecord />;
    }

    return content;
}
