import { useBiblStore } from '../hooks/useBiblStore';
import MandalaSkeleton from '../../views/common/MandalaSkeleton';
import { getCollNames } from './catalogUtils';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { NavSearch } from '../search/NavSearch';
import { useCatSearchStore } from '../hooks/useCatSearchStore';
import { getLangCodeForString } from './utils';

export function CatalogContentHeader({ coll, ed, text = 'none' }) {
    let bibl = useBiblStore((state) => state.doc);
    let { query, queryLang, active, getQueryWords } = useCatSearchStore(
        (state) => state
    );
    // console.log("query in header", query);
    const collnames = getCollNames(coll);
    const isSearch = useRouteMatch('/catalog/search');
    const txttitle =
        bibl?.subtype === 'text' && bibl?.titles?.length > 0
            ? bibl?.titles[0]
            : false;
    let breadcrumbs = (
        <>
            <Link to={`/catalog/${coll}`} className="breadcrumb-item">
                {collnames[0]?.name}
                {!coll && !ed && 'Overview'}
            </Link>
            {bibl?.ed && bibl?.edsig !== 'main' && (
                <Link to={`/catalog/${coll}/${ed}`} className="breadcrumb-item">
                    {bibl.ed}
                </Link>
            )}
            {ed === 'eds' && <a className="breadcrumb-item">Overview</a>}
            {txttitle && (
                <Link
                    to={`/catalog/${coll}/${ed}/${text}`}
                    className={`breadcrumb-item ${txttitle?.lang?.join(' ')}`}
                >
                    {txttitle.title}
                </Link>
            )}
        </>
    );
    if (isSearch) {
        //const
        let querylabel = getQueryWords() || 'Everything';
        // console.log(querylabel);
        if (Array.isArray(querylabel)) {
            if (querylabel.length === 1) {
                querylabel = querylabel[0];
                if (querylabel === '*:*') {
                    querylabel = 'Everything';
                }
            } else {
                const last = querylabel.pop();
                querylabel = querylabel.join(', ') + ` and ${last}`;
            }
        }
        breadcrumbs = (
            <a className="breadcrumb-item no-link">
                Search Results for {querylabel}{' '}
            </a>
        );
    }

    const searchback =
        active && !isSearch ? (
            <div className="search-back">
                <Link to={'/catalog/search'}>Back to Search Results</Link>
            </div>
        ) : null;

    return (
        <header
            id="c-content__header__main"
            className={`c-content__header__main legacy mandala classical`}
        >
            <div
                id="c-content__header__main__wrap"
                className="c-content__header__main__wrap legacy"
            >
                <h1 className={'c-content__header__main__title alttitle'}>
                    <span className={`icon u-icon__catalogs`}></span>
                </h1>

                <div className={'c-content__header__breadcrumb breadcrumb'}>
                    <Link to={`/catalog/`} className="breadcrumb-item">
                        Catalog
                    </Link>
                    {breadcrumbs}
                </div>
                {searchback}
                {/*
                <div className={'c-content__header__search search'}>
                    <NavSearch />
                </div>
                */}
            </div>
        </header>
    );
}
