import React from 'react';
import { useCatSearchStore } from '../hooks/useCatSearchStore';
import { HtmlCustom } from '../../views/common/MandalaMarkup';

/**
 * Highlights a plain title if not using a title from Solr hits which come already highlighted this way.
 *
 * @param title - The title in question
 * @param sstr - The search string of the current search
 * @returns {*} - a react component with the embedded search string highlighted with span[@class='hl']
 * @constructor
 */
export function HighlightTitleHit({ title, sstr = false }) {
    const catsearch = useCatSearchStore();

    // console.log('in highlight title hit', catsearch.isActive);
    if (!catsearch.isActive) {
        return title;
    }
    const hlTitle = (title, q) => {
        if (title?.length > q?.length && q.length > 1) {
            return title.replace(
                new RegExp(q, 'g'),
                `<span class="hl">${q}</span>`
            );
        } else {
            return title;
        }
    };
    if (Array.isArray(title)) {
        title = title?.length > 0 ? title[0] : '';
    }
    if (typeof title !== 'string' || title?.length < 5) {
        return title;
    }
    // console.log(catsearch.isAdvanced);
    let qsts = catsearch.getQueryWords();
    if (!qsts || qsts[0] === '*:*' || qsts.length === 0) {
        return title;
    }
    let patt = qsts.length === 1 ? qsts : `(${qsts.join('|')})`;
    const regex = new RegExp(patt, 'gi');
    //console.log("qsts", qsts);

    const parts = title.split(regex);
    return (
        <>
            {parts.map((part, index) =>
                qsts.some((word) =>
                    part.toLowerCase().includes(word.toLowerCase())
                ) ? (
                    <span key={index} className="hl">
                        {part}
                    </span>
                ) : (
                    part
                )
            )}
        </>
    );
}
