import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import './TermDictionaries.css';
import {
    containsTibetan,
    getLangClass,
    getUniquePropIds,
    markTibetan,
} from '../../common/utils';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';
import $ from 'jquery';

/**
 * Kmaps Terms Api that returns order of dictionaries to display
 * @returns {Promise<any>}
 */
export const getDictionaryOrder = async () => {
    const apiurl = process.env?.REACT_APP_DICTIONARY_LIST;
    const { data } = await axios.request(apiurl);
    return data;
};

/**
 * TermDictionaries is original component by Gerard
 * Andres updated SOLR field names since then. Than created singular TermDictionary below based on this.
 * 2023-01-02
 * @param definitions
 * @returns {JSX.Element}
 * @constructor
 */
const TermDictionaries = ({ definitions, kmapData }) => {
    const {
        isLoading: isDictOrderLoading,
        data: dictOrderData,
        isError: isDictOrderError,
        error: dictOrderError,
    } = useQuery(
        ['kmaps', 'terms', 'dict', 'order', 'api'],
        () => getDictionaryOrder(),
        {
            staleTime: 3600000, // 1 hr in milliseconds
        }
    );

    if (isDictOrderLoading) {
        return null;
    }

    // Once order is loaded get list of dictionary titles in order, filtered for existing defintitions
    let dictOrder = dictOrderData
        ?.map((d, di) => {
            return d?.code;
        })
        .filter((dt, dti) => {
            return Object.keys(definitions).includes(dt); // only keep dictionary titles if their def exists for this term
        });

    // Just in case the order doesn't load or something goes wrong, use the order of the definitions given
    if (!dictOrder || dictOrder?.length === 0) {
        dictOrder = Object.keys(definitions);
    }

    const relatedToDefs = kmapData._childDocuments_.filter((c, ci) => {
        return (
            c?.block_child_type === 'related_terms' &&
            c?.related_terms_relation_code_s === 'is.related.to'
        );
    });

    const defOrder = dictOrder.filter((k, i) => {
        return Object.keys(definitions).includes(k);
    });

    // dictOrder is a list of definition keys by dictionary name
    return (
        <div className="sui-termDicts__wrapper">
            <div className="sui-termDicts__content">
                {defOrder?.map((key, i) => {
                    let thedef = definitions[key]; // definitions are arrays bec. dicts might have more than one
                    let thedict = dictOrderData?.filter((d, di) => {
                        return d?.code === key;
                    });
                    if (thedict?.length > 0) {
                        thedict = thedict[0];
                    }
                    if (key === 'gualberto' && thedef?.length === 2) {
                        // console.log("the def", thedef);
                        return (
                            <div className={`dict-defs ${key}`} key={key}>
                                <div className="sui-termDicts__dict-name">
                                    {i + 1}. <span>{thedict?.title}</span>
                                </div>
                                <RelatedTermsDictionary
                                    dict={key}
                                    docs={relatedToDefs}
                                />
                                <TermDictSpecialList type={key} def={thedef} />
                            </div>
                        );
                    }
                    return (
                        <div className={`dict-defs ${key}`} key={key}>
                            <div className="sui-termDicts__dict-name">
                                {i + 1}. <span>{thedict?.title}</span>
                            </div>
                            <RelatedTermsDictionary
                                dict={key}
                                docs={relatedToDefs}
                            />
                            <ul className="sui-termDicts__dict-wrapper">
                                {thedef.map((def) => {
                                    let defcont = getDefContent(def).replace(
                                        /(<p[^>]+?>|<p>|<\/p>)/gim,
                                        ''
                                    );
                                    let langclass = getLangClass(
                                        def.related_definitions_language_s
                                    );

                                    if (
                                        langclass === 'sa' &&
                                        def.related_definitions_source_code_s ===
                                            'negi'
                                    ) {
                                        langclass = 'sa-ltn';
                                    }

                                    if (containsTibetan(defcont)) {
                                        defcont = markTibetan(defcont);
                                    }
                                    return (
                                        <li
                                            className="sui-termDicts__dict"
                                            data-solrid={def.id}
                                            key={def.id}
                                        >
                                            <div
                                                className={
                                                    'sui-termDicts__dict-def ' +
                                                    langclass
                                                }
                                            >
                                                {ReactHtmlParser(defcont)}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="sui-termDicts__dict-extra">
                                <span className="sui-termDicts__dict-extra-lang">
                                    Language:
                                </span>{' '}
                                <span className="sui-termDicts__dict-extra-lang-text ">
                                    {
                                        definitions[key][0]
                                            ?.related_definitions_language_s
                                    }
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

function getDefContent(subdoc) {
    const langsuff = getUniquePropIds(
        subdoc,
        /related_definitions_content_(\w+)/
    );
    if (langsuff.length > 0) {
        const fnm = 'related_definitions_content_' + langsuff[0];
        return subdoc[fnm];
    }
    return '';
}

function TermDictSpecialList({ type, def }) {
    if (type === 'gualberto') {
        const def1cont = getDefContent(def[0]).replace(
            /(<p[^>]+?>|<p>|<\/p>)/gim,
            ''
        );
        const def2cont = getDefContent(def[1]).replace(
            /(<p[^>]+?>|<p>|<\/p>)/gim,
            ''
        );
        const langclass = getLangClass(def[0].related_definitions_language_s);
        return (
            <>
                <ul className="sui-termDicts__dict-wrapper">
                    <li
                        className="sui-termDicts__dict"
                        data-solrid={def[0].id}
                        key={def[0].id}
                    >
                        <div className={'sui-termDicts__dict-def ' + langclass}>
                            {ReactHtmlParser(def1cont)} [
                            {ReactHtmlParser(def2cont)}]
                        </div>
                    </li>
                </ul>
                <div className="sui-termDicts__dict-extra">
                    <span className="sui-termDicts__dict-extra-lang">
                        Language:
                    </span>{' '}
                    <span className="sui-termDicts__dict-extra-lang-text ">
                        {def[0]?.related_definitions_language_s}
                    </span>
                </div>
            </>
        );
    }
}

export default TermDictionaries;

function RelatedTermsDictionary({ dict, docs }) {
    const relations = docs?.filter((d, di) => {
        return d?.related_terms_relation_source_s === dict;
    });
    if (relations?.length > 0) {
        const combrels = {};
        relations.map((r, ri) => {
            const combkey = r?.related_terms_relation_label_s;
            if (!Object.keys(combrels).includes(combkey)) {
                combrels[combkey] = [];
            }
            let lkn = r?.related_terms_id_s?.replace('-', '/');
            if (!lkn?.length > 0) {
                lkn = '#';
            } else {
                lkn = '/' + lkn;
            }
            combrels[combkey].push(
                <Link to={lkn} key={`${dict}-relterm-${ri}`}>
                    {r?.related_terms_header_s}
                </Link>
            );
        });
        return (
            <dl>
                {Object.keys(combrels).map((k, ki) => {
                    const reactkey = `${dict}-${k}-relterm-${ki}`;

                    return (
                        <React.Fragment key={reactkey}>
                            <dt>{k}</dt>
                            <dd>
                                {combrels[k].map((term, ti) => {
                                    const suffix =
                                        ti < combrels[k].length - 1 ? (
                                            ', '
                                        ) : (
                                            <br />
                                        );
                                    return (
                                        <span key={`term-${ki}-${ti}`}>
                                            {term}
                                            {suffix}
                                        </span>
                                    );
                                })}
                            </dd>
                        </React.Fragment>
                    );
                })}
            </dl>
        );
    }
    return null;
}
