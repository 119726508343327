import React, { useEffect, useState } from 'react';
import { useCatSearchStore } from '../hooks/useCatSearchStore';
import { useCatSolr } from '../hooks/useCatSolr';
import THLSkeleton from '../common/THLSkeleton';
import { Link } from 'react-router-dom';
import { GoTriangleDown, GoTriangleRight } from 'react-icons/all';
import HitsSummary from './HitSummary';
import HitList from './HitList';
import { HighlightTitleHit } from './HighlightTitleHit';

/**
 * Displays a single result
 * @param bucket
 * @constructor
 */
export function ResultItem({ bibid, count, open }) {
    const search = useCatSearchStore();
    const setIsNew = search.setIsNew;
    const [isOpen, setOpen] = useState(open);
    const noQuery =
        search.query === '' || search.query === '*:*' || search.query === null;
    // TODO: change id to filter query and use this to do the search for highlighting
    const qobj = {
        q: `id:${bibid}`,
        fl: `*,[child limit=5000]`,
    };
    const {
        isLoading: isSearchLoading,
        data: results,
        isError: isSearchError,
        error: searchError,
    } = useCatSolr(qobj);

    useEffect(() => {
        setOpen(open);
    }, [open]);

    if (isSearchLoading) {
        return <THLSkeleton />;
    }
    if (isSearchError) {
        return <li>Error: ${searchError}</li>;
    }
    if (!results?.docs?.length > 0) {
        console.warn(`${bibid} not found for search results!`);
        return null;
    }

    // console.log(bibid);
    // console.log(search);

    let doc = results.docs[0];
    let header = (
        <>
            {doc?.display_id} ({bibid}): <HitsSummary bibid={bibid} />
        </>
    );
    let qstr = search.getQueryWords();
    if (doc?.subtype === 'volume') {
        let title = doc?.titles?.title
            ? doc?.titles?.title[0]
            : doc?.titles[0]?.title[0];
        if (!title || title?.length === 0) {
            title = <span className="en">"Not found"</span>;
        } else {
            title = <span className="en">{title}</span>;
        }
        header = (
            <>
                {doc?.display_id}: {title}{' '}
                {search.isActive && <HitsSummary bibid={bibid} count={count} />}
            </>
        );
    } else if (doc?.subtype === 'text') {
        let ttitle = doc?.titles[0]?.title[0];
        if (!ttitle) {
            ttitle = doc?.titles[0]?.title[1]
                ? doc?.titles[0]?.title[1]
                : 'Untitled';
        }
        const idlink = (
            <Link
                to={`${doc?.coll}/${doc?.edsig}/${doc?.text_num}`}
                onClick={() => {
                    setIsNew(false);
                }}
            >
                {doc?.display_id}
            </Link>
        );
        const title = (
            <span className="bo">
                <HighlightTitleHit title={ttitle} sstr={qstr} />
            </span>
        );
        // console.log("count in text hit", count);

        // Show hits only if 1. search is active and 2. it is either basic search or there is only one row in the advanced search form
        // If you are looking for docs with multiple query items (rows in form), cannot count number of hits, bec. it doesn't make sense, each doc is in itself a unique hit
        const showHits =
            search.isActive &&
            (!search.isAdvanced ||
                search?.advancedData?.formRows?.length === 1);
        header = (
            <>
                {idlink}: {title}{' '}
                {showHits && <HitsSummary bibid={bibid} count={count} />}
            </>
        );
    }
    const item_type = doc?.subtype === 'volume' ? 'vol' : doc?.subtype;

    const icon = isOpen ? (
        <GoTriangleDown className="toggle-icon down" />
    ) : (
        <GoTriangleRight className="toggle-icon right" />
    );

    const hits = isOpen && search.query ? <HitList bibid={bibid} /> : null;

    const toggleOpen = () => {
        setOpen(!isOpen);
    };

    // TODO: Get hits to work for advanced search. Currently not displaying
    return (
        <li className={item_type}>
            {/* && !search.isAdvanced is because hits are not working for adv search */}
            {!noQuery && <a onClick={toggleOpen}>{icon}</a>}
            {header} <span className="d-none">[{bibid}]</span>
            {hits}
        </li>
    );
}
