import React, { useEffect, useState } from 'react';
import { useSolr } from '../../../hooks/useSolr';
import { useHistory } from 'react-router-dom';

const TermGlossarySearchBox = () => {
    const history = useHistory();
    const bogusquery = 'nothing-to-search-for';
    const [query, setQuery] = useState(bogusquery);
    const [showSuggestions, setShowSuggestions] = useState(false);

    const suggestionListQuery = {
        index: 'terms',
        params: {
            q: `header:${query}*`,
            fq: [
                `tree:terms`,
                '-associated_subject_ids:10524',
                'ancestors_new.alpha:*',
            ],
            rows: 100,
            fl: '*',
            facet: false,
            sort: `cascading_position_i ASC`,
        },
    };
    const {
        isLoading: areSuggestionsLoading,
        data: suggestions,
        isError: isSuggestionsError,
        error: suggestionError,
    } = useSolr(['suggestion-list', query], suggestionListQuery, false, false);

    const handleChange = (e) => {
        const value = e.target.value;
        setQuery(value);
        if (value) {
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };

    const handleSelectSuggestion = (suggestion) => {
        setShowSuggestions(false);
        const tid = suggestion.uid.replace('terms-', '');
        history.push(`/terms/glossary/nod/${tid}`);
    };

    useEffect(() => {
        if (typeof query !== 'string' || query === '') {
            setQuery(bogusquery);
        }
    }, [query]);

    if (isSuggestionsError) {
        console.log('suggestion error', suggestionError);
    }

    return (
        <div style={{ position: 'relative', width: '300px' }}>
            <input
                type="text"
                value={query !== bogusquery ? query : ''}
                onChange={handleChange}
                placeholder="Search..."
                className="gloss-search"
            />
            {areSuggestionsLoading && (
                <div style={{ marginTop: '10px' }}>Loading...</div>
            )}
            {!areSuggestionsLoading &&
                showSuggestions &&
                suggestions?.numFound > 0 && (
                    <ul className="suggestion-list">
                        {suggestions.docs.map((suggestion, index) => (
                            <li
                                key={index}
                                onClick={() =>
                                    handleSelectSuggestion(suggestion)
                                }
                                onMouseDown={(e) => e.preventDefault()} // Prevents input blur on click
                            >
                                {suggestion.header} ({suggestion.id})
                            </li>
                        ))}
                    </ul>
                )}
        </div>
    );
};

export default TermGlossarySearchBox;
