import React, { useEffect, useRef, useState } from 'react';
import { getBiblLang, getEdResource } from '../common/catalogUtils';
import { useBiblStore } from '../hooks/useBiblStore';
import './TextStyled.scss';
import { usePagerText } from '../hooks/usePagerText';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import $ from 'jquery';
import { useInfiniteSection } from '../hooks/useInfiniteSection';
import { useParams } from 'react-router-dom';
import { HtmlCustom } from '../common/HtmlCustom';
import { useCatSearchStore } from '../hooks/useCatSearchStore';
import { useCatSolr } from '../hooks/useCatSolr';
import MandalaSkeleton from '../../views/common/MandalaSkeleton';
import { useCatSettings } from '../hooks/useCatSettings';

/**
 * TextReaderText : for marked up, structured digital texts with IDed sections and possible multiple milestones
 *
 * @returns {JSX.Element}
 * @constructor
 */
export function TextReaderText() {
    const { doc, dynamicSections, setDynamicSections } = useBiblStore();
    const biblid = doc?.id;
    const bypassme = !doc || !!dynamicSections;

    // console.log("First bypass", bypassme, biblid);
    const query = {
        q: `type:textsection AND bibid_s:${biblid}`,
        rows: 5000,
        fl: 'id,section_num_s,section_title_s, section_level_i, section_position_i',
        sort: 'section_sort_s asc',
    };
    const {
        isLoading: isSectListLoading,
        data: sectionList,
        isError: isSectListError,
        error: sectListError,
    } = useCatSolr(query, bypassme);

    useEffect(() => {
        if (!isSectListLoading && !isSectListError && sectionList) {
            setDynamicSections(sectionList?.docs);
        }
    }, [sectionList]);

    if (isSectListLoading) {
        return <MandalaSkeleton />;
    }

    return <TextReaderTextPages />;
}

function TextReaderTextPages() {
    const pager = usePagerText();
    const { doc, dynamicSections } = useBiblStore();
    const { targString } = useCatSettings((state) => state);

    // const {sid} = useParams();
    const biblid = doc?.id;
    const bibsid = [biblid, pager?.section].join(':');
    const sids = dynamicSections?.map((ds) =>
        [biblid, ds?.section_num_s].join(':')
    );
    const bypassme = !doc || !dynamicSections;

    // console.log("Should we bypass", bypassme, dynamicSections);

    // useInfiniteSection loads more content as the Load More button scrolls into view
    const {
        data: pgdata,
        error: fetchingError,
        isFetching: isFetchingPages,
        ifFetchingPreviousPage: isFetchingPreviousSection,
        isFectionNextPage: isFetchingNextSection,
        hasNextPage: hasNextSection,
        hasPreviousPage: hasPreviousSection,
        fetchNextPage: fetchNextSection,
        fetchPreviousPage: fetchPreviousSection,
        status: fetchingStatus,
    } = useInfiniteSection(bibsid, sids, bypassme);

    const rootRef = useRef();
    const loadMoreButtonRef = useRef();

    useIntersectionObserver({
        root: rootRef,
        target: loadMoreButtonRef,
        onIntersect: fetchNextSection,
        enabled: hasNextSection,
        threshold: 0.5,
    });

    useEffect(() => {
        // Set usePager.loaded to a list of page keys. For later scrolling, to determine if already loaded
        pager.setLoaded(
            pgdata?.pages?.map((sect, si) => {
                return sect?.sid;
            })
        );
        // Make sure at least 3 sections are loaded
        if (pgdata?.pages?.length < 5) {
            // console.log("has next section", hasNextSection);
            if (hasPreviousSection) {
                fetchPreviousSection();
            }
            if (hasNextSection) {
                fetchNextSection();
            }
        }
    }, [pgdata]);
    /*
    useEffect(() => {
        if (pager?.setSection) {
            pager.setSection(sid);
        }
    }, [sid]);

 */

    // Highlights TOC on scroll (can't use intersection observers because subsections in cutsom markup)
    const checkVisible = () => {
        if (window?.checkvisible) {
            return;
        }
        window.checkvisible = setTimeout(function () {
            try {
                const trt = $('#text-reader-text');
                const partop = trt?.offset()?.top;
                const parbottom = trt?.height() + partop;
                const sels = $('.c-text-section, .c-text-subsection');
                const vbls = [];
                let lastone = '';
                $.each(sels, function (si, s) {
                    let sel = $(s);
                    let seltop = sel.offset().top;
                    if (seltop > partop - 120 && seltop < parbottom) {
                        vbls.push(sel.attr('id').replace('s-', ''));
                    }
                    if (seltop < parbottom) {
                        lastone = sel.attr('id').replace('s-', '');
                    }
                });
                if (vbls.length === 0) {
                    vbls.push(lastone);
                }
                pager.updateVisible(vbls);
                window.clearTimeout(window.checkvisible);
                window.checkvisible = null;
            } catch (e) {
                console.log('Error', e);
            }
        }, 800);
    };

    useEffect(() => {
        checkVisible();
    }, []);

    if (fetchingStatus === 'loading') {
        return <p>Loading...</p>;
    }
    if (fetchingError || fetchingStatus === 'error') {
        console.log(fetchingError);
        return <p>Oops! Something went wrong loading Text Reader sections!</p>;
    }

    if (!doc?.hastext) {
        return (
            <div id="text-reader-text" className="c-text-view">
                <p>There is no digital text for this work.</p>
            </div>
        );
    }

    const mylang = getBiblLang(doc);
    const cn = 'c-text-view ' + mylang;
    // console.log("data for " + pager?.section, data);
    // console.log('Text reader text', pgdata);

    // Below is the link to a text's intertextuality.
    const intertext_base = `${process.env.REACT_APP_ITX_HOME}${process.env.REACT_APP_TLA_PATH}__ID__/?sPage=__SPAGE__&targets=${targString}&mode=view#/`;
    const handleClick = (e) => {
        const el = e.target;
        const classes = el.className;
        if (classes?.includes('milestone') && classes?.includes('page')) {
            const pgid = `${doc.id.replace('-bib', '-text')}-v${doc?.vol_num}p${
                el?.dataset?.value
            }`;
            const itlink = intertext_base.replace('__SPAGE__', pgid);
            window.open(itlink, '_blank');
        }
    };
    return (
        <div
            id="text-reader-text"
            className={cn}
            ref={rootRef}
            onScroll={checkVisible}
            onClick={handleClick}
        >
            {pgdata.pages.map((s, si) => {
                return <TextSection key={`ts-${si}-${s.sid}`} doc={s} />;
            })}

            <div className="infinite-load-btn">
                <button
                    ref={loadMoreButtonRef}
                    className={
                        isFetchingNextSection
                            ? 'loading'
                            : hasNextSection
                            ? 'canload'
                            : 'finis'
                    }
                    onClick={() => fetchNextSection()}
                    disabled={!hasNextSection || isFetchingNextSection}
                >
                    {isFetchingNextSection
                        ? 'Loading more...'
                        : hasNextSection
                        ? 'Load More'
                        : '————————————'}
                </button>
            </div>
            <div>
                {isFetchingPages && !isFetchingNextSection
                    ? 'Fetching...'
                    : null}
            </div>
        </div>
    );
}

function TextSection({ doc }) {
    const search = useCatSearchStore();
    // console.log(search?.query);
    // const qstr = search?.query?.replace(/"/g, '');
    // const rep = new RegExp(qstr, 'g');
    const qwrds = search.getQueryWords();
    const sectRef = useRef(null);
    let sectmu = doc?.section;
    if (sectmu && qwrds?.length > 0) {
        sectmu = qwrds.reduce((mu, wrd) => {
            const pts = mu.split(wrd);
            return pts.join(`<span class="hl">${wrd}</span>`);
        }, sectmu);
    }
    const tid = doc.tid;
    const sid = doc.sid;
    return (
        <div
            id={`s-${sid}`}
            className={`c-text-section`}
            data-id={sid}
            ref={sectRef}
        >
            <HtmlCustom markup={sectmu} key={`${tid}-${sid}`} />
        </div>
    );
}
