import React, { useEffect, useState } from 'react';
import './css/CatalogFrame.scss';
import SplitPane, { Pane } from 'react-split-pane';
import SearchResults from './search/SearchResults';
import SearchFacets from './search/SearchFacets';
import TablesOfContents from './catnav/TablesOfContents';
import $ from 'jquery';
import { FrameContents } from './FrameContents';
import { useBiblStore } from './hooks/useBiblStore';
import MandalaSkeleton from '../views/common/MandalaSkeleton';
import jsonpAdapter from './common/axios-jsonp';
import axios from 'axios';
import { NavSearch } from './search/NavSearch';
import { useCatSearchStore } from './hooks/useCatSearchStore';

/**
 * CatalogFrame displays all parts of the catalog. It is a split pane with the content on the left and
 * a TOC on the right. The TOC is the catalog TOC when looking at bibl records or a text's sections TOC
 * when looking at a text. There is a variable below for "biblcontent" which is the content for the main,
 * content section of the frame. There are two tabs for the catalog or texts TOC on the right.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function CatalogFrame({ search = false }) {
    const bibStore = useBiblStore();
    const catSearchStore = useCatSearchStore();
    const [collsLoaded, setCollsLoaded] = useState(false);

    const [dragged, setDragged] = useState(false); // Drag state for split screen view

    // Queries SOLR for all collection and edition bibls and stores in BiblStore organized by collection for easier access and disambiguation of edition sigla
    useEffect(() => {
        // Add mdl-catalog class to body for scroll control of split frame
        document.body.classList.add('catframe');
        // Load Catalog info from search
        if (!bibStore?.collinfo) {
            const promise = getCatalogInfo();
            promise
                .then((resp) => {
                    // console.log('data in promise', resp);
                    if (resp?.data?.response?.docs?.length > 0) {
                        bibStore.setCollInfo(resp.data.response);
                    }
                })
                .finally(() => {
                    setCollsLoaded(true);
                });
        } else if (!collsLoaded) {
            // console.log('already bibstor coll info', bibStore.collinfo);
            setCollsLoaded(true);
        }
        return () => {
            // remove mdl-catalog class from body on unmount
            document.body.classList.remove('catframe');
        };
    }, []);

    if (!collsLoaded) {
        return <MandalaSkeleton />;
    }

    const startDrag = () => {
        setDragged(true);
    };
    const endDrag = () => {
        setDragged(false);
        $('.Pane1 .l-frame-wrapper').width($('.Pane1').width());
        // console.log("pane 1 width: " + $('.Pane1').width(), $('.Pane1'));
    };

    return (
        <>
            <SplitPane
                split="vertical"
                primary="second"
                className="l-catalog-frame"
                defaultSize="25vw"
                allowResize={true}
                /* defaultSize={search ? '15vw' : '35vw'}
                allowResize={!search}*/
                onDragStarted={startDrag}
                onDragFinished={endDrag}
            >
                <Pane id="catalog-sp-1" className="l-frame-content">
                    <div className="l-frame-wrapper">
                        {!search && <FrameContents />}
                        {search && <SearchResults />}
                        {/*
                        {!catSearch?.isNew && framecontent}
                        {catSearch?.isNew && <SearchResults />} */}
                    </div>
                </Pane>
                <Pane id="catalog-sp-2" className="l-frame-toc">
                    <div className="l-frame-wrapper">
                        {/*<h1 className="h4 mt-1 font-italic">{edhead}</h1>*/}
                        {!search && <TablesOfContents />}
                        {search && (
                            <>
                                <NavSearch /> <SearchFacets />
                            </>
                        )}
                        {/*{tocType === 'facets' && <SearchFacets />} */}
                    </div>
                </Pane>
            </SplitPane>
        </>
    );
}

async function getCatalogInfo() {
    try {
        const request = {
            url: process.env.REACT_APP_SOLR_URL,
            adapter: jsonpAdapter,
            dataType: 'jsonp',
            callbackParamName: 'json.wrf',
            headers: {
                'Content-Type': null,
            },
            params: {
                q: `subtype:(collection edition)`,
                fq: ['type:tibbibl'],
                fl: '*',
                rows: 1000,
            },
        };
        return await axios.request(request);
    } catch (e) {
        console.error('could not fetch coll data', e);
    }
}
